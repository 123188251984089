






import { Vue, Component } from 'vue-property-decorator';

import { ping } from '#/index';

import { isWellConnected } from '../lib/token';

import { setInterval } from 'timers';

@Component
export default class ConnectionManager extends Vue {
  private errorWithServer = false;
  private message: string = '';

  public created() {
    setInterval(async () => {
      this.errorWithServer = !(await ping());
      this.message = 'Server is offline';
    }, 5 * 1000);

    setInterval(async () => {
      const authenticated = await isWellConnected(this.$store);

      if (!authenticated) {
        this.errorWithServer = true;
        this.message = 'You are not authenticated';
      }
    }, 1000 /*s*/ * 60 /*m*/ * 15);
  }
}
