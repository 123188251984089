




import { Vue, Component } from 'vue-property-decorator';

import { ElectrumVue } from './electrum-vue';

@Component
export default class NotAvailableVue extends ElectrumVue {
  public mounted() {
    this.updateTitle('Service not available');
  }
}
