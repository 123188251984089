





















































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { mapState } from 'vuex';

import ConnectionManager from '@/components/ConnectionManager.vue';

import { ElectrumVue, Menus } from './views/electrum-vue';
import { setInterval } from 'timers';

import { isWellConnected } from './lib/token';

import { mobileCheck } from './lib/util';

@Component({ components: { ConnectionManager } })
export default class App extends Vue {
  public get currentView(): ElectrumVue {
    return this.$refs.view as ElectrumVue;
  }

  private collapsed: boolean = mobileCheck();
  private menus: Menus = [];
  private title: string = '';
  private openKeys: string[] = [];
  private mobileCheck = mobileCheck();

  public updateTitle(title: string) {
    this.title = title;
  }

  private openKeysForRoute(): string[] {
    const result: string[] = [];

    // switch (this.$route.name) {
    //   case 'apps':
    //   case 'upload':
    //   case 'apple-store-requests':
    //     result[0] = 'submenu-apps';
    //     break;
    // }

    return result;
  }

  private selectedKeysForRoute(): string[] {
    const result: string[] = [];

    // switch (this.$route.name) {
    //   case 'apps':
    //     result[0] = 'apps';
    //     break;
    //   case 'upload':
    //     result[0] = 'upload-app';
    //   case 'apple-store-requests':
    //     result[0] = 'apple-store-requests';
    //     break;
    // }

    return result;
  }

  private logout() {
    this.$store.commit('auth/clearToken');
  }

  private onOpenChange(openKeys: string[]) {
    const lastElement = openKeys.pop();

    this.openKeys = lastElement ? [lastElement] : [];
  }
}
