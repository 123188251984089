import { uploadStatus } from '@/api/upload';

import { setInterval, clearInterval } from 'timers';

enum State {
  identifying = 0,
  identified = 1,
  unindentified = 2,
}

export class IdentifyingQueue {
  private toIdentify: Array<{
    title: string;
    description: string;
    state: State;
    id: string;
    appid: number | null;
    app: any | null;
    filename: string;
  }> = [];

  private interval: any;

  get list() {
    return this.toIdentify;
  }

  public enqueue({ name, taskId }: { name: string; taskId: string }) {
    this.toIdentify.unshift({
      title: name,
      description: 'Identifying',
      state: State.identifying,
      id: taskId,
      appid: null,
      app: null,
      filename: name,
    });
  }

  public start() {
    this.stop();

    this.interval = setInterval(async () => {
      for (const e of this.toIdentify) {
        if (e.state === State.identifying) {
          const status = (await uploadStatus(e.id)).data;

          if (status.terminated) {
            if (status.identified) {
              this.onSuccess(e.filename);

              e.state = State.identified;

              const { pkgName, sha1, version } = status.app;

              e.appid = status.app.id;
              e.app = status.app;
              e.description = JSON.stringify({ pkgName, sha1, version });
            } else {
              this.onFail(e.filename);

              e.state = State.unindentified;
              e.description = 'Not a correct application';
            }
          }
        }
      }
    }, 5000);
  }

  public stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  protected onSuccess(name: string) {
    return;
  }

  protected onFail(name: string) {
    return;
  }
}
