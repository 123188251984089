













import { Component } from 'vue-property-decorator';

import { ElectrumAuthVue } from '../auth-vue';

import ListCrawlers from '@/components/sense/ListCrawlers.vue';
import ListCrawlerTrackingEntries from '@/components/sense/ListCrawlerTrackingEntries.vue';

@Component({ components: { ListCrawlers, ListCrawlerTrackingEntries } })
export default class ListAppsVue extends ElectrumAuthVue {
  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected mounted() {
    this.updateTitle('Apps');
  }
}
