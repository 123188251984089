const tokenKey = 'token';

export function localStorageOr(key: string, defaultValue: string) {
  let result = localStorage.getItem(key);

  if (result === null) {
    result = defaultValue;
  }

  return result;
}

export function token(): string {
  return localStorageOr(tokenKey, '');
}
