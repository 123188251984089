import { MatchAction } from './match-action';
import { ReportPerformances } from './report-performances';

export function sortReport(report: any) {
  report = report.elements[0];

  const result = new Map<string, any>();

  const permissionsSecurity: any[] = [];
  const permissions: any[] = [];
  const matches = new MatchAction();
  const libs: any[] = [];
  const events: any[] = [];
  const vulns: any[] = [];
  const performances = new ReportPerformances();
  const metas: any[] = [];
  const dces: any[] = [];
  const networks: any[] = [];
  const stats: any[] = [];

  const elements1 = report.elements as any[];

  for (const element of elements1) {
    switch (element.name) {
      case 'Permission-Security': {
        permissionsSecurity.push(element);
        break;
      }
      case 'Permission': {
        permissions.push(element);
        break;
      }
      case 'Libs': {
        libs.push(element);
        break;
      }
      case 'Vuln': {
        vulns.push(element);
        break;
      }
      case 'Match': {
        // matches.push(element);
        matches.addMatch(element);
        break;
      }
      case 'Network': {
        networks.push(element);
        break;
      }
      case 'Event': {
        events.push(element);
        break;
      }
      case 'DCE': {
        // dces.push(element);
        break;
      }
      case 'Stats': {
        // stats.push(element);
        break;
      }
      case 'Meta': {
        // metas.push(element);
        break;
      }
      case 'Performance': {
        performances.addPerformance(element.attributes);
        break;
      }
      default: {
        // statements;
        break;
      }
    }
  }

  // Sort the attributs of easly comparable elements
  // const sortCallback = (n1: any, n2: any) => this.stringComparator(n1.attributes.Name, n2.attributes.Name);
  // Sorted by Name
  permissions.sort((n1: any, n2: any) =>
    n1.attributes.Name.localeCompare(n2.attributes.Name),
  );

  // Sorted by Id
  libs.sort((n1: any, n2: any) =>
    n1.attributes.Id.localeCompare(n2.attributes.Id),
  );

  // Sorted by ProtectionLevel
  permissionsSecurity.sort((n1: any, n2: any) =>
    n1.attributes.ProtectionLevel.localeCompare(n2.attributes.ProtectionLevel),
  );

  // Sorted by vulnerability name or the elements
  vulns.sort((n1: any, n2: any) => {
    let sresult = n1.attributes.vuln_name.localeCompare(
      n2.attributes.vuln_name,
    );

    if (sresult === 0 && n1.attributes.element && n1.attributes.element) {
      sresult = n1.attributes.element.localeCompare(n2.attributes.element);
    }
    return sresult;
  });

  // Sorted by vulnerability name or the elements
  networks.sort((n1: any, n2: any) => {
    let sresult = n1.attributes.Protocol.localeCompare(n2.attributes.Protocol);

    if (sresult === 0) {
      sresult = n1.attributes.DestinationAddress.localeCompare(
        n2.attributes.DestinationAddress,
      );

      if (sresult === 0) {
        sresult = n1.attributes.DestinationPort.localeCompare(
          n2.attributes.DestinationPort,
        );
      }
    }

    return sresult;
  });

  // Match sort By action & destination & length of attibutes
  matches.sort();

  result.set('Permission-Security', permissionsSecurity);
  result.set('Permission', permissions);
  result.set('Libs', libs);
  result.set('Vuln', vulns);
  result.set('Match', matches);
  result.set('Network', networks);
  result.set('Event', events);
  result.set('DCE', dces);
  result.set('Stats', stats);
  result.set('Meta', metas);
  result.set('Performance', performances);

  return result;
}
