





















import { Vue, Component } from 'vue-property-decorator';

import { Crawler, listCrawlers } from '#/crawler';

@Component
export default class ListCrawlers extends Vue {
  private crawlers: Crawler[] = [];
  private interval: any = null;

  protected async created() {
    this.crawlers = await listCrawlers();

    this.interval = setInterval(async () => {
      this.crawlers = await listCrawlers();
    }, 20 * 1000);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
