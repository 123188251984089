import Vue from 'vue';
import Vuex from 'vuex';

import * as engine from './modules/engine';
import * as settings from './modules/settings';
import * as auth from './modules/auth';
import * as analysis from './modules/analysis';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    engine,
    settings,
    auth,
    analysis,
  },
});
