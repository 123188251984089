






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import gql from 'graphql-tag';

import { mapState } from 'vuex';

import { Analyses, listAnalyses, Engine, lookFor } from '#/analysis';
import { listApps } from '#/apps';

import { Button } from 'ant-design-vue';
import { token } from '../../lib/local-storage';

import Output from '@/components/analyses/Output.vue';

@Component
export default class ListAnalysesVue extends Vue {
  @Prop(Object) private readonly app: any;
  private page: number = 1;
  private count = 0;
  private analyses: any[] = [];

  private readonly pageSize = 5;

  protected async mounted() {
    await this.refresh();
  }

  public async refresh() {
    const { count, results } = await lookFor({
      pkgName: this.app.pkgName,
      version: this.app.version,
      sha1: this.app.sha1,
      osType: this.app.osType,
      take: this.pageSize,
      skip: (this.page - 1) * this.pageSize,
    });

    this.count = count;
    this.analyses = results;
  }

  private async newPage(page: number) {
    this.page = page;

    await this.refresh();
  }

  protected formatAnalysisStatus(status: string) {
    let result = '';

    switch (status) {
      case 'TODO':
        result = 'To do';
        break;
      case 'WIP':
        result = 'Work in progress';

        break;
      case 'FINISHED':
      default:
        result = 'Finished';
    }

    return result;
  }

  protected formatTime(time: number) {
    let suffix = 'ms';

    if (time > 1000) {
      time /= 1000;
      suffix = 's';
    }

    if (time > 60) {
      time /= 60;
      suffix = 'm';
    }

    if (time > 60) {
      time /= 60;
      suffix = 'h';
    }

    return `${time.toFixed(2)}${suffix}`;
  }

  protected formatMemory(memory: number) {
    let suffix = 'b';

    if (memory > 1024) {
      memory /= 1024;
      suffix = 'kb';
    }

    if (memory > 1204) {
      memory /= 1024;
      suffix = 'mb';
    }

    if (memory > 1024) {
      memory /= 1024;
      suffix = 'gb';
    }

    return `${memory.toFixed(2)}${suffix}`;
  }
}
