


































































































import { Component } from 'vue-property-decorator';

import {
  listApps,
  AppsGroup as IAppsGroup,
  getAppsGroupById,
  addAppToGroup,
} from '#/apps';

import { ElectrumAuthVue } from '../auth-vue';
import { token } from '../../lib/local-storage';
import { APPS_SERVER } from '@/servers';

interface App {
  id: number;
  osType: string;
  pkgName: string;
  sha1: string;
  version: string;
  displayName: string;
}

@Component
export default class AppsGroup extends ElectrumAuthVue {
  private appsGroup: IAppsGroup | null = null;
  private interval: any = null;
  private disableAddRandom = false;
  private links: string[] = [];

  private readonly pageSize = 10;

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async created() {
    this.update();

    this.interval = setInterval(async () => {
      this.update();
    }, 20 * 1000);
  }

  protected linkToDownloadApp(index: number) {
    return `${APPS_SERVER}/downloadAppWithLink?id=${this.links[index]}`;
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  protected mounted() {
    this.updateTitle('Apps group');
  }

  protected async update() {
    this.appsGroup = await getAppsGroupById(this.$route.params.id);
  }

  private async addRandom(limit: number) {
    this.disableAddRandom = true;

    let appsList = await listApps(10, 0, false);

    const count = appsList.count;
    const groupId = this.$route.params.id;

    let current = 0;

    const addingPromises: Array<Promise<any>> = [];

    while (current < limit) {
      appsList = await listApps(500, Math.floor(Math.random() * count));

      const apps = appsList.apps;

      for (let i = 0; i < 100; i++) {
        if (current >= limit) {
          break;
        }

        const r = Math.floor(Math.random() * apps.length);
        const app = apps[r];

        if (app.osType === 'Android') {
          addingPromises.push(addAppToGroup(apps[r].id, groupId));
        }

        current++;
      }
    }

    await Promise.all(addingPromises);
    await this.update();
    this.disableAddRandom = false;
  }

  private async downloadApp(index: number, app: App) {
    if (!this.links[index]) {
      const headers = new Headers();
      headers.append('Content-type', 'application/json');
      headers.append('Authorization', `Bearer ${token()}`);

      const link = (
        await (
          await fetch(`${APPS_SERVER}/generateDownloadAppLink`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
              pkgName: app.pkgName,
              osType: app.osType,
              version: app.version,
            }),
          })
        ).json()
      ).id;

      this.$set(this.links, index, link);
    }
  }
}
