































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { lookFor } from '#/analysis';
import { AppsGroup, listAppsGroups, getAppsGroupById, AppInfo } from '#/apps';

import MultiReport from '@/components/sense/MultiReport.vue';

@Component({
  components: {
    MultiReport,
  },
})
export default class ReportSynthesis extends Vue {
  private appsGroups: AppsGroup[] = [];
  private interval: any = null;
  private groupName: string = '';
  private menus: any = null;
  private selectedAppGroup: string | null = null;
  private apps: any[] = [];
  private reportIds: string[] = [];
  private reportsIdsWithAppInfo: [AppInfo, string][] = [];
  private pkg: string = 'all';
  private versos: string = 'all';
  private signature: string = 'all';
  private progress: string = '0';

  private readonly pageSize = 10;

  protected async created() {
    this.appsGroups = await listAppsGroups();
  }

  protected setProgress(progress: string) {
    this.progress = ((Number(progress) / 100) * 70 + 30).toFixed(0);
  }

  @Watch('selectedAppGroup')
  private async selectGroup(selectedAppGroup: string) {
    const apps = (await getAppsGroupById(selectedAppGroup)).apps!;

    const menus: any = {};

    for (const app of apps) {
      const { osType, sha1, pkgName, version } = app;
      const versos = `${osType} ${version}`;

      if (!(pkgName in menus)) {
        menus[app.pkgName] = {};
      }

      const smenu = menus[pkgName];

      if (!(versos in smenu)) {
        smenu[versos] = [];
      }

      smenu[versos].push(sha1);
    }

    this.menus = menus;

    const reportsIdsWithAppInfo: [AppInfo, string][] = [];

    for (const [index, app] of apps.entries()) {
      try {
        const { count, results } = await lookFor({ ...app, take: 1 });

        if (count > 0) {
          reportsIdsWithAppInfo.push([app, results[0].id]);
        }
      } catch (e) {
        console.log(
          `<${JSON.stringify(app)}> can not be retrieved, skipped (sorry cat)`,
        );
      }

      this.progress = (((index + 1) / apps.length) * 30).toFixed(0);
    }

    this.reportsIdsWithAppInfo = reportsIdsWithAppInfo;

    this.filter();
  }

  private selectPackage() {
    this.versos = 'all';
    this.signature = 'all';
    this.filter();
  }

  private selectVersion() {
    this.signature = 'all';
    this.filter();
  }

  private filter() {
    const reportIds = [];

    if (this.pkg === 'all') {
      this.versos = 'all';
    }

    if (this.versos === 'all') {
      this.signature = 'all';
    }

    for (const [app, id] of this.reportsIdsWithAppInfo) {
      const { pkgName, osType, version, sha1 } = app;
      const versos = `${osType} ${version}`;

      if (this.pkg !== 'all' && this.pkg !== pkgName) {
        continue;
      }

      if (this.versos !== 'all' && this.versos !== versos) {
        continue;
      }

      if (this.signature !== 'all' && this.signature !== sha1) {
        continue;
      }

      reportIds.push(id);
    }

    this.reportIds = reportIds;
  }
}
