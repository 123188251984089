// tslint:disable:no-shadowed-variable

import { generateToken } from '#/auth';

import { decode } from 'jsonwebtoken';

import { ActionTree, Commit } from 'vuex';

function localStorageOr(key: string, defaultValue: string | null) {
  let result = localStorage.getItem(key);

  if (result === null) {
    result = defaultValue;
  }

  return result;
}

interface IUser {
  username: string;
}

export const namespaced = true;

interface IState {
  token: string | null;
}

export const state: IState = {
  token: localStorageOr('token', null),
};

export const getters = {
  token(state: IState): string | null {
    return state.token;
  },
  user(state: IState): IUser | null {
    if (state.token === null) {
      return null;
    }

    return decode(state.token) as IUser;
  },
};

export const actions: ActionTree<any, {}> = {
  async login(
    { commit }: { commit: Commit },
    { username, password }: { username: string; password: string },
  ) {
    let result = false;

    const token = await generateToken(username, password);

    if (token) {
      result = true;
      commit('setToken', token);
    }

    return result;
  },
};

export const mutations = {
  setToken(state: IState, token: string) {
    localStorage.setItem('token', token);
    state.token = token;
  },
  clearToken(state: IState) {
    localStorage.removeItem('token');
    state.token = null;
  },
};
