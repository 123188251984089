// tslint:disable

import Axios from 'axios';

import { token } from '@/lib/local-storage';

import { REPERTOIRE_SERVER } from '@/servers';

interface XMLAppReport {
  retrieved: boolean;
  report: string;
}

interface AppReport {
  retrieved: boolean;
  report: any;
}

export interface CategorizedURL {
  pid: number;
  active: boolean;
  creationDate: string;
  lastModificationDate: string;
  category: 'phishing';
  url: string;
  protocol: 'http:' | 'https:';
  kind: 'URL' | 'DOMAIN';
}

export async function retrieveAppReport(id: string): Promise<AppReport> {
  return (
    await Axios.get<AppReport>(
      `${REPERTOIRE_SERVER}/app-report/retrieveReport`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params: {
          id,
        },
      },
    )
  ).data;
}

export async function retrieveXMLAppReport(id: string): Promise<XMLAppReport> {
  return (
    await Axios.get<XMLAppReport>(
      `${REPERTOIRE_SERVER}/app-report/retrieveXMLReport`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params: {
          id,
        },
      },
    )
  ).data;
}

export async function listCategorizedURL(params?: {
  skip?: number;
  take?: number;
  from?: Date | string;
  to?: Date | string;
}): Promise<{
  count: number;
  results: CategorizedURL[];
}> {
  if (params) {
    if (params.from) {
      if (params.from instanceof Date) {
        params.from = params.from.toISOString();
      }
    }

    if (params.to) {
      if (params.to instanceof Date) {
        params.to = params.to.toISOString();
      }
    }
  }

  const result: any = (
    await Axios.get<{ count: number; result: CategorizedURL[] }>(
      `${REPERTOIRE_SERVER}/url/listCategorizedURL`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params,
      },
    )
  ).data;

  result.results = result.result;

  return result;
}

export async function addCategorizedURL(params: {
  kind: string;
  url: string;
  protocol: string;
  category: string;
}): Promise<void> {
  await Axios.post(`${REPERTOIRE_SERVER}/url/createCategorizedURL`, params, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
}
