import { PerfAttribut } from './perf-attribut';

export class PerfResult {
  public name: string;
  public mUsed: PerfAttribut;
  public dTime: PerfAttribut;

  public constructor(nameValue: string) {
    this.mUsed = new PerfAttribut('MUsed');
    this.dTime = new PerfAttribut('DTime');
    this.name = nameValue;
  }
}
