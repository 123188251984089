import Axios from 'axios';

import { token } from '@/lib/local-storage';

import { BENCHMARK_SERVER } from '@/servers';

import { Tag } from './analysis';

export interface Benchmark {
  id: string;
  tags: Tag[];
  name: string;
  count: number;
  rows?: any[];
}

export async function listBenchmarks(): Promise<Benchmark[]> {
  return (
    await Axios.get<Benchmark[]>(`${BENCHMARK_SERVER}/listBenchmarks`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function createBenchmark(
  groupId: string,
  tagsAnalysis1: string[],
  tagsAnalysis2: string[],
  name?: string,
): Promise<void> {
  await Axios.post(
    `${BENCHMARK_SERVER}/createBenchmark`,
    { groupId, tagsAnalysis1, tagsAnalysis2, name },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function startBenchmark(id: string): Promise<void> {
  await Axios.post(
    `${BENCHMARK_SERVER}/startBenchmark`,
    { id },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function deleteBenchmark(id: string): Promise<void> {
  await Axios.post(
    `${BENCHMARK_SERVER}/deleteBenchmark`,
    { id },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function getBenchmarkById(
  id: string,
  take: number,
  skip: number,
): Promise<Benchmark> {
  return (
    await Axios.get<Benchmark>(`${BENCHMARK_SERVER}/getBenchmarkById`, {
      params: {
        id,
        take,
        skip,
      },
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function getBenchmarkRowById(pid: string): Promise<any> {
  return (
    await Axios.get<Benchmark>(`${BENCHMARK_SERVER}/getBenchmarkRowById`, {
      params: {
        id: pid,
      },
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}
