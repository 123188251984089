export class MatchDestination {
  private mapMatchByDestinations: Map<string, any[]>;
  private arrayOfDestinations: string[];
  private arrayOfAllMatches: any[];

  public constructor() {
    this.mapMatchByDestinations = new Map();
    this.arrayOfDestinations = [];
    this.arrayOfAllMatches = [];
  }

  public addMatch(matchIn: any) {
    const matchDestAddr = matchIn.attributes.destination;
    this.arrayOfAllMatches.push(matchIn);

    // The array was already created
    if (this.mapMatchByDestinations.has(matchDestAddr)) {
      const matchDest = this.mapMatchByDestinations.get(matchDestAddr)!;
      matchDest.push(matchIn);
    } else {
      // Create new array and add the new destination to the list
      const newMatch = [];
      newMatch.push(matchIn);
      this.arrayOfDestinations.push(matchDestAddr);
      this.mapMatchByDestinations.set(matchDestAddr, newMatch);
    }
  }
  public getMatches(destination: string) {
    return this.mapMatchByDestinations.get(destination);
  }

  public getDestinations() {
    return this.arrayOfDestinations;
  }

  public sort() {
    for (const dest of this.arrayOfDestinations) {
      this.mapMatchByDestinations.get(dest)!.sort((n1: any, n2: any) => {
        if (n1.attributes.length === n2.attributes.length) {
          return 0;
        } else if (n1.attributes.length < n2.attributes.length) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  }

  public getAllMatches() {
    return this.arrayOfAllMatches;
  }

  public compare(report: MatchDestination) {
    const result = new Map();
    let i = 0;
    let j = 0;

    const deleted = [];
    const added = [];
    const included = [];

    const thisSize = this.arrayOfDestinations.length;
    const reportSize = report.arrayOfDestinations.length;

    while (i < thisSize && j < reportSize) {
      const resDest = this.stringComparator(
        this.arrayOfDestinations[i],
        report.arrayOfDestinations[j],
      );

      switch (resDest) {
        case -1: {
          // All match of the same Destination aren't in the new report
          for (const matchTmp of this.mapMatchByDestinations.get(
            this.arrayOfDestinations[i],
          )!) {
            deleted.push(matchTmp);
          }
          ++i;
          break;
        }
        case 0: {
          const matches1 = this.mapMatchByDestinations.get(
            this.arrayOfDestinations[i],
          );
          let matches2 = report.mapMatchByDestinations.get(
            report.arrayOfDestinations[j],
          );

          const toDelete = [];
          for (const matchTmp1 of matches1!) {
            let findMatch = false;

            for (const matchTmp2 of matches2!) {
              // Jump match with inferior number of attributes
              if (
                Object.keys(matchTmp1.attributes).length >
                Object.keys(matchTmp2.attributes).length
              ) {
                continue;
              }
              const resComp = this.attributesComparisons(matchTmp1, matchTmp2);

              if (resComp === 0) {
                // We have find a matching match
                findMatch = true;
                toDelete.push(matchTmp2);
                break;
              } else if (resComp === 1) {
                // Match is include in an other
                findMatch = true;
                toDelete.push(matchTmp2);
                // Pair of values
                included.push([matchTmp1, matchTmp2]);
                break;
              }
            }

            if (!findMatch) {
              deleted.push(matchTmp1);
            }
          }

          // Delete matching Match
          for (const toDeleteTmp of toDelete) {
            matches2 = matches2!.filter((val: any) => {
              return val !== toDeleteTmp;
            });
          }

          // The rest of the match are new ones
          for (const matchAdded of matches2!) {
            added.push(matchAdded);
          }

          ++i;
          ++j;
          break;
        }
        case 1: {
          // All match of the same Destination aren't in the old report
          for (const matchTmp of report.mapMatchByDestinations.get(
            report.arrayOfDestinations[j],
          )!) {
            deleted.push(matchTmp);
          }
          ++j;
          break;
        }
      }
    }

    // Push values in the Map
    result.set('deleted', deleted);
    result.set('added', added);
    result.set('included', included);
    return result;
  }

  // -1 not equal, 0 equal, 1 included
  private attributesComparisons(match1: any, match2: any) {
    const lengthComparison =
      Object.keys(match1.attributes).length ===
      Object.keys(match2.attributes).length;

    for (const [key, attr] of Object.entries(match1.attributes)) {
      if (key === 'action' || key === 'destination') {
        continue;
      }

      if (key in match2.attributes) {
        const val = match2.attributes[key];
        if (attr !== val) {
          return -1;
        }
      } else {
        return -1;
      }
    }

    if (lengthComparison) {
      return 0;
    }
    return 1;
  }

  // Return 0 if equal, 1 if string1 < string2 and 2 if string1 > string2
  private stringComparator(string1: string, string2: string) {
    if (string1 === string2) {
      return 0;
    }
    if (string1 < string2) {
      return -1;
    }
    return 1;
  }
}
