import { MatchAction } from './compare/match-action';
import { ReportPerformances } from './compare/report-performances';

export function compare(report1: Map<string, any>, report2: Map<string, any>) {
  const resComparisonSecPerm = permissionSecurityComparator(
    report1.get('Permission-Security'),
    report2.get('Permission-Security'),
  );
  const resComparisonPerm = permissionComparator(
    report1.get('Permission'),
    report2.get('Permission'),
  );
  const resComparisonVuln = vulnComparator(
    report1.get('Vuln'),
    report2.get('Vuln'),
  );
  const resComparisonMatch = matchComparator(
    report1.get('Match'),
    report2.get('Match'),
  );
  /*const resComparisonEvent = eventComparator(
    report1.get('Event'),
    report2.get('Event'),
  );
  const resComparisonDCE = dceComparator(
    report1.get('DCE'),
    report2.get('DCE'),
  );
  const resComparisonStat = statsComparator(
    report1.get('Stats'),
    report2.get('Stats'),
  );
  const resComparisonMeta = metaComparator(
    report1.get('Meta'),
    report2.get('Meta'),
  );*/
  const resComparisonPerf = performanceComparator(
    report1.get('Performance'),
    report2.get('Performance'),
  );

  const removed = [];
  const added = [];
  const modified = [];

  // DELETED lines
  for (const delSecPerm of resComparisonSecPerm.get('deleted')) {
    removed.push(delSecPerm);
  }

  for (const delPerm of resComparisonPerm.get('deleted')) {
    removed.push(delPerm);
  }

  for (const delVuln of resComparisonVuln.get('deleted')) {
    removed.push(delVuln);
  }
  for (const delMatch of resComparisonMatch.get('deleted')) {
    removed.push(delMatch);
  }

  // ADDED lines
  for (const addSecPerm of resComparisonSecPerm.get('added')) {
    added.push(addSecPerm);
  }

  for (const addPerm of resComparisonPerm.get('added')) {
    added.push(addPerm);
  }

  for (const addVuln of resComparisonVuln.get('added')) {
    added.push(addVuln);
  }

  for (const addMatch of resComparisonMatch.get('added')) {
    added.push(addMatch);
  }

  // Included
  for (const includMatch of resComparisonMatch.get('included')) {
    modified.push(includMatch);
  }

  const performances = resComparisonPerf;

  return {
    removed,
    added,
    modified,
    performances,
  };
}

// Return 0 if equal, -1 if string1 < string2 and 1 if string1 > string2
function stringComparator(string1: string, string2: string) {
  /*if (string1 === string2) {
      return 0;
    }
    if (string1 < string2) {
      return -1;
    }
    return 1;*/

  return string1.localeCompare(string2);
}

function permissionSecurityComparator(report1: any, report2: any) {
  const result = new Map();

  const deleted = [];
  const added = [];
  let i = 0;
  let j = 0;

  while (i < report1.length && j < report2.length) {
    const value = stringComparator(
      report1[i].attributes.ProtectionLevel,
      report2[j].attributes.ProtectionLevel,
    );
    switch (value) {
      case -1: {
        deleted.push(report1[i]);
        ++i;
        break;
      }
      case 0: {
        ++i;
        ++j;
        break;
      }
      case 1: {
        added.push(report2[j]);
        ++j;
        break;
      }
    }
  }

  // Add the last elements when we finished analyzing a report
  if (i < report1.length) {
    for (let itmp = i; itmp < report1.length; ++itmp) {
      deleted.push(report1[itmp]);
    }
  }

  if (j < report2.length) {
    for (let jtmp = j; jtmp < report2.length; ++jtmp) {
      added.push(report1[jtmp]);
    }
  }

  // Push values in the Map
  result.set('deleted', deleted);
  result.set('added', added);

  return result;
}

/*function libsComparator(report1: any, report2: any) {
  const result = new Map();

  const deleted = [];
  const added = [];
  let i = 0;
  let j = 0;

  while (i < report1.length && j < report2.length) {
    const value = stringComparator(
      report1[i].attributes.Id,
      report2[j].attributes.Id,
    );
    switch (value) {
      case -1: {
        deleted.push(report1[i]);
        ++i;
        break;
      }
      case 0: {
        ++i;
        ++j;
        break;
      }
      case 1: {
        added.push(report2[j]);
        ++j;
        break;
      }
    }
  }

  // Add the last elements when we finished analyzing a report
  if (i < report1.length) {
    for (let itmp = i; itmp < report1.length; ++itmp) {
      deleted.push(report1[itmp]);
    }
  }

  if (j < report2.length) {
    for (let jtmp = j; jtmp < report2.length; ++jtmp) {
      added.push(report1[jtmp]);
    }
  }

  // Push values in the Map
  result.set('deleted', deleted);
  result.set('added', added);

  return result;
}*/

function permissionComparator(report1: any, report2: any) {
  const result = new Map();

  const deleted = [];
  const added = [];
  let i = 0;
  let j = 0;

  while (i < report1.length && j < report2.length) {
    const value = stringComparator(
      report1[i].attributes.Name,
      report2[j].attributes.Name,
    );
    switch (value) {
      case -1: {
        deleted.push(report1[i]);
        ++i;
        break;
      }
      case 0: {
        ++i;
        ++j;
        break;
      }
      case 1: {
        added.push(report2[j]);
        ++j;
        break;
      }
    }
  }

  // Add the last elements when we finished analyzing a report
  if (i < report1.length) {
    for (let itmp = i; itmp < report1.length; ++itmp) {
      deleted.push(report1[itmp]);
    }
  }

  if (j < report2.length) {
    for (let jtmp = j; jtmp < report2.length; ++jtmp) {
      added.push(report1[jtmp]);
    }
  }

  // Push values in the Map
  result.set('deleted', deleted);
  result.set('added', added);

  return result;
}

function vulnComparator(report1: any, report2: any) {
  const result = new Map();

  const deleted = [];
  const added = [];
  let i = 0;
  let j = 0;

  while (i < report1.length && j < report2.length) {
    const value = stringComparator(
      report1[i].attributes.vuln_name,
      report2[j].attributes.vuln_name,
    );
    //  -1: a < b, 0: a === b, 1: a > b
    switch (value) {
      case -1: {
        deleted.push(report1[i]);
        ++i;
        break;
      }
      case 0: {
        // Special resolution in case of elements present
        if (report1[i].attributes.element) {
          const res = stringComparator(
            report1[i].attributes.element,
            report2[j].attributes.element,
          );
          if (res === -1) {
            deleted.push(report1[i]);
            ++i;
          } else if (res === 1) {
            added.push(report2[j]);
            ++j;
          } else {
            ++i;
            ++j;
          }
        } else {
          ++i;
          ++j;
        }
        break;
      }
      case 1: {
        added.push(report2[j]);
        ++j;
        break;
      }
    }
  }

  // Add the last elements when we finished analyzing a report
  if (i < report1.length) {
    for (let itmp = i; itmp < report1.length; ++itmp) {
      deleted.push(report1[itmp]);
    }
  }

  if (j < report2.length) {
    for (let jtmp = j; jtmp < report2.length; ++jtmp) {
      added.push(report1[jtmp]);
    }
  }

  // Push values in the Map
  result.set('deleted', deleted);
  result.set('added', added);

  return result;
}

/*function networkComparator(report1: any, report2: any) {
  const result = new Map();

  const deleted = [];
  const added = [];
  let i = 0;
  let j = 0;

  // <Network Action="Connect" Protocol="http" DestinationAddress="192.168.1.141" DestinationPort="8066"/>
  while (i < report1.length && j < report2.length) {
    const resProtocol = stringComparator(
      report1[i].attributes.Protocol,
      report2[j].attributes.Protocol,
    );
    //  -1: a < b, 0: a === b, 1: a > b
    switch (resProtocol) {
      case -1: {
        deleted.push(report1[i]);
        ++i;
        break;
      }
      case 0: {
        const resDestAddr = stringComparator(
          report1[i].attributes.DestinationAddress,
          report2[j].attributes.DestinationAddress,
        );
        switch (resDestAddr) {
          case -1: {
            deleted.push(report1[i]);
            ++i;
            break;
          }
          case 0: {
            const resDestPort = stringComparator(
              report1[i].attributes.DestinationPort,
              report2[j].attributes.DestinationPort,
            );
            switch (resDestPort) {
              case -1: {
                deleted.push(report1[i]);
                ++i;
                break;
              }
              case 0: {
                ++i;
                ++j;
                break;
              }
              case 1: {
                added.push(report2[j]);
                ++j;
                break;
              }
            }
            break;
          }
          case 1: {
            added.push(report2[j]);
            ++j;
            break;
          }
        }
        break;
      }
      case 1: {
        added.push(report2[j]);
        ++j;
        break;
      }
    }
  }

  // Add the last elements when we finished analyzing a report
  if (i < report1.length) {
    for (let itmp = i; itmp < report1.length; ++itmp) {
      deleted.push(report1[itmp]);
    }
  }

  if (j < report2.length) {
    for (let jtmp = j; jtmp < report2.length; ++jtmp) {
      added.push(report1[jtmp]);
    }
  }

  // Push values in the Map
  result.set('deleted', deleted);
  result.set('added', added);

  return result;
}*/

function matchComparator(report1: MatchAction, report2: MatchAction) {
  return report1.compare(report2);
}

/*function eventComparator(report1: any, report2: any) {
  const result: any[] = [];
  return result;
}

function metaComparator(report1: any, report2: any) {
  const result: any[] = [];
  return result;
}

function dceComparator(report1: any, report2: any) {
  const result: any[] = [];
  return result;
}

function statsComparator(report1: any, report2: any) {
  const result: any[] = [];
  return result;
}*/

function performanceComparator(
  report1: ReportPerformances,
  report2: ReportPerformances,
) {
  return report1.compare(report2);
}
