


















import { Vue, Component, Watch } from 'vue-property-decorator';

import { OperatingSystem, listOperatingSystems } from '#/knowledge';

@Component
export default class ListOperatingSystems extends Vue {
  private page: number = 1;
  private count = 0;
  private operatingSystems: OperatingSystem[] = [];
  private interval: any = null;

  private readonly pageSize = 10;

  protected async created() {
    await this.refresh();

    this.interval = setInterval(async () => {
      await this.refresh();
    }, 20 * 1000);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  @Watch('page')
  private async newPage() {
    await this.refresh();
  }

  private async refresh() {
    const { count, results } = await listOperatingSystems({
      skip: (this.page - 1) * this.pageSize,
      take: this.pageSize,
    });

    this.count = count;
    this.operatingSystems = results;
  }
}
