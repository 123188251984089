import Axios from 'axios';

import { token } from '@/lib/local-storage';
import { UPLOAD_SERVER } from '@/servers';

export async function uploadJSFile(
  content: string,
  name: string,
  version: string,
) {
  return Axios.post<{ taskId: string }>(
    `${UPLOAD_SERVER}/uploadFileWithMetadata`,
    {
      content,
      name,
      version,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function uploadStatus(taskId: string) {
  return Axios.get(`${UPLOAD_SERVER}/checkUploadStatus`, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
    params: {
      taskId,
    },
  });
}
