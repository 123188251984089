export class PerfAttribut {
  public name: string;
  public value: number;
  public lighter: boolean;

  public constructor(nameValue: string) {
    this.value = 0;
    this.lighter = true;
    this.name = nameValue;
  }
}
