







































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { mobileCheck } from '../lib/util';

@Component({})
export default class LoginVue extends Vue {
  private oneFailTry: boolean = false;
  private form: any;

  private isMobileApp = mobileCheck();

  protected async beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  private handleSubmit(e: any) {
    e.preventDefault();

    this.form.validateFields(async (err: Error, values: any) => {
      if (!err) {
        const login = await this.$store.dispatch('auth/login', values);

        if (login) {
          this.oneFailTry = false;

          const routeQuery = this.$route.query;

          if (routeQuery.redirect) {
            const redirect = routeQuery.redirect as string;

            if (redirect) {
              this.$router.push({
                path: redirect,
              });
            }
          } else {
            this.$router.push({
              name: 'upload',
            });
          }
        } else {
          this.oneFailTry = true;
        }
      }
    });
  }
}
