









































































































































































































































import { Button } from 'ant-design-vue';

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import gql from 'graphql-tag';

import { mapState } from 'vuex';

import { retrieveXMLAppReport } from '#/repertoire';
import { analyze } from '#/analyses';
import { AppsGroup, listAppsGroups } from '#/apps';
import {
  Benchmark,
  listBenchmarks,
  createBenchmark,
  startBenchmark,
  deleteBenchmark,
  getBenchmarkById,
  getBenchmarkRowById,
} from '#/benchmark';

import { ElectrumAuthVue } from '../auth-vue';
import { token } from '../../lib/local-storage';

import { compare } from '@/lib/report/compare';
import { sortReport } from '@/lib/report/compare/sort-report';

import BenchmarkSummary from '@/components/apps/BenchmarkSummary.vue';

import { APPS_SERVER } from '@/servers';

import { xml2js } from 'xml-js';

import xmlFormat from 'xml-formatter';

import { Bar } from 'vue-chartjs';

@Component({
  components: { Bar, BenchmarkSummary },
})
export default class BenchmarkVue extends ElectrumAuthVue {
  private benchmark: Benchmark | null = null;
  private page: number = 1;
  private count: number = 0;
  private rows: any[] = [];
  private tags1: any[] = [];
  private tags2: any[] = [];
  private benchmarkStarted = false;
  private events: any[] = [];
  private progress = 0;
  private currentProcessApp = '';
  private currentProcessDescription = '';

  private summary: Record<
    string,
    { new: string[]; lost: string[]; conserved: string[] }
  > = {
    data: { new: [], lost: [], conserved: [] },
    destination: { new: [], lost: [], conserved: [] },
    event: { new: [], lost: [], conserved: [] },
    warning: { new: [], lost: [], conserved: [] },
    network: { new: [], lost: [], conserved: [] },
    libs: { new: [], lost: [], conserved: [] },
    vulnerability: { new: [], lost: [], conserved: [] },
  };

  get displayedRows() {
    const { page, pageSize } = this;

    const front = page * pageSize;

    return this.rows
      .filter((row) => row.terminated)
      .slice(front, front + pageSize);
  }

  private xml2js = xml2js;

  private readonly pageSize = 10;
  private xmlFormat = xmlFormat;

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async created() {
    this.update();
  }

  protected mounted() {
    this.updateTitle('Benchmark');
  }

  protected async update() {
    this.benchmark = await getBenchmarkById(
      this.$route.params.id,
      this.pageSize,
      (this.page - 1) * this.pageSize,
    );
  }

  @Watch('page')
  private async newPage() {
    await this.update();
  }

  private async retrieveXMLAppReport(id: string) {
    return retrieveXMLAppReport(id);
  }

  private async selectRow(id: number) {
    /*if (this.benchmark) {
      const row: any = this.benchmark.rows![id];

      if (!row.report1 || !row.report2) {
        const nrow = await getBenchmarkRowById(row.pid);

        let report1 = (await retrieveXMLAppReport(nrow.request1.id)).report;
        let report2 = (await retrieveXMLAppReport(nrow.request2.id)).report;

        if (report1) {
          report1 = xmlFormat(report1);
        }

        if (report2) {
          report2 = xmlFormat(report2);
        }

        Vue.set(this.benchmark.rows!, id, {
          ...nrow,
          report1,
          report2,
        });
      }
    }*/
  }

  private async start() {
    this.benchmarkStarted = true;

    const id = this.$route.params.id;

    let n = 0;
    const step = 10;

    this.summary = {
      data: { new: [], lost: [], conserved: [] },
      destination: { new: [], lost: [], conserved: [] },
      event: { new: [], lost: [], conserved: [] },
      warning: { new: [], lost: [], conserved: [] },
      network: { new: [], lost: [], conserved: [] },
      libs: { new: [], lost: [], conserved: [] },
      vulnerability: { new: [], lost: [], conserved: [] },
    };

    const cacheSummaryCount = {
      data: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
      destination: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
      event: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
      warning: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
      network: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
      libs: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
      vulnerability: {
        new: new Map<string, [number, number]>(),
        lost: new Map<string, [number, number]>(),
        conserved: new Map<string, [number, number]>(),
      },
    };

    let benchmark = await getBenchmarkById(id, step, n);

    const rows: any[] = [];
    const count = benchmark.count;

    this.count = count;
    this.rows = rows;
    this.tags1 = (benchmark as any).tagsAnalysis1;
    this.tags2 = (benchmark as any).tagsAnalysis2;

    while (n < count) {
      let appId = n;

      for (const row of benchmark.rows!) {
        const app = (this.benchmark as any).about.apps[appId];
        const pid = row.pid;

        rows.push({ app, pid, terminated: false });
        appId++;
      }

      n += step;
      benchmark = await getBenchmarkById(id, step, n);

      this.currentProcessDescription = `Retrieve benchmark rows - ${n}/${count}`;
    }

    for (const [progress, row] of Object.entries(rows)) {
      try {
        const { app, pid } = row;

        this.currentProcessApp = app.pkgName;
        this.currentProcessDescription = 'Retrieve report';

        const nrow = await getBenchmarkRowById(pid);

        const xmlReport1 = (await retrieveXMLAppReport(nrow.request1.id))
          .report;
        const xmlReport2 = (await retrieveXMLAppReport(nrow.request2.id))
          .report;

        const report1 = xml2js(xmlReport1);
        const report2 = xml2js(xmlReport2);

        row.report1 = report1;
        row.report2 = report2;

        row.xmlReport1 = xmlReport1;
        row.xmlReport2 = xmlReport2;

        row.formattedReport1 = xmlFormat(xmlReport1);
        row.formattedReport2 = xmlFormat(xmlReport2);

        if (report1 && report2) {
          this.currentProcessDescription = 'Process summary';

          await this.processSummary(report1, report2, cacheSummaryCount);

          this.currentProcessDescription = 'Process deep comparaison';

          const sreport1 = sortReport(report1);
          const sreport2 = sortReport(report2);

          const { added, modified, performances, removed } = compare(
            sreport1,
            sreport2,
          );

          row.added = added;
          row.modified = modified;
          row.performances = performances;
          row.removed = removed;
        }

        row.terminated = true;
        this.events.unshift({ app });
      } catch (e) {
        // tslint:disable-next-line
        console.error('An error appends', e);
      }

      this.progress = Math.floor((Number(progress) / count) * 100);
    }

    this.progress = 100;
  }

  private async processSummary(report1: any, report2: any, count: any) {
    this.handle(
      'Match',
      'data',
      { report1, report2 },
      this.summary.data,
      count.data,
      this.isIOWrite,
    );

    this.handle(
      'Match',
      'destination',
      { report1, report2 },
      this.summary.destination,
      count.destination,
      this.isIOWrite,
    );

    this.handle(
      'Match',
      'id',
      { report1, report2 },
      this.summary.event,
      count.event,
      this.isEvent,
    );

    this.handle(
      'Warning',
      'Pattern',
      { report1, report2 },
      this.summary.warning,
      count.warning,
    );

    this.handle(
      'Network',
      'DestinationAddress',
      { report1, report2 },
      this.summary.network,
      count.network,
    );

    this.handle(
      'Libs',
      'Name',
      { report1, report2 },
      this.summary.libs,
      count.libs,
    );

    this.handle(
      'Vuln',
      'vuln_name',
      { report1, report2 },
      this.summary.vulnerability,
      count.vulnerability,
    );
  }

  private handle(
    key: string,
    attribute: string,
    row: any,
    target: any,
    count: Record<string, Map<string, [number, number]>>,
    condition: ((e: any) => boolean) | null = null,
  ) {
    for (const added of this.handleAdded(key, attribute, row, condition)) {
      if (count.new.has(added)) {
        const [currentCount, pos] = count.new.get(added)!;

        count.new.set(added, [currentCount + 1, pos]);
        Vue.set(target.new, pos, `${added} (${currentCount + 1})`);
      } else {
        count.new.set(added, [1, target.new.length]);
        target.new.push(`${added} (1)`);
      }
    }

    for (const removed of this.handleRemoved(key, attribute, row, condition)) {
      if (count.lost.has(removed)) {
        const [currentCount, pos] = count.lost.get(removed)!;

        count.lost.set(removed, [currentCount + 1, pos]);
        Vue.set(target.lost, pos, `${removed} (${currentCount + 1})`);
      } else {
        count.lost.set(removed, [1, target.lost.length]);
        target.lost.push(`${removed} (1)`);
      }
    }

    for (const conserved of this.handleConserved(
      key,
      attribute,
      row,
      condition,
    )) {
      if (count.conserved.has(conserved)) {
        const [currentCount, pos] = count.conserved.get(conserved)!;

        count.conserved.set(conserved, [currentCount + 1, pos]);
        Vue.set(target.conserved, pos, `${conserved} (${currentCount + 1})`);
      } else {
        count.conserved.set(conserved, [1, target.conserved.length]);
        target.conserved.push(`${conserved} (1)`);
      }
    }
  }

  private handleAdded(
    key: string,
    attribute: string,
    row: any,
    condition: ((e: any) => boolean) | null = null,
  ) {
    const [vulnerabilities1, vulnerabilities2] = this.createComparaisonSetOf(
      key,
      attribute,
      row,
      condition,
    );

    const result = [];

    for (const vuln of vulnerabilities2) {
      if (!vulnerabilities1.has(vuln)) {
        result.push(vuln);
      }
    }

    return result;
  }

  private handleRemoved(
    key: string,
    attribute: string,
    row: any,
    condition: ((e: any) => boolean) | null = null,
  ) {
    const [vulnerabilities1, vulnerabilities2] = this.createComparaisonSetOf(
      key,
      attribute,
      row,
      condition,
    );

    const result = [];

    for (const vuln of vulnerabilities1) {
      if (!vulnerabilities2.has(vuln)) {
        result.push(vuln);
      }
    }

    return result;
  }

  private handleConserved(
    key: string,
    attribute: string,
    row: any,
    condition: ((e: any) => boolean) | null,
  ) {
    const [vulnerabilities1, vulnerabilities2] = this.createComparaisonSetOf(
      key,
      attribute,
      row,
      condition,
    );

    const result = [];

    for (const vuln of vulnerabilities1) {
      if (vulnerabilities2.has(vuln)) {
        result.push(vuln);
      }
    }

    return result;
  }

  private createComparaisonSetOf(
    key: string,
    attribute: string,
    row: any,
    condition: ((e: any) => boolean) | null,
  ) {
    const { report1, report2 } = row;

    const set1: Set<string> = this.createSetOf(
      key,
      attribute,
      report1.elements[0].elements,
      condition,
    );

    const set2: Set<string> = this.createSetOf(
      key,
      attribute,
      report2.elements[0].elements,
      condition,
    );

    return [set1, set2];
  }

  private createSetOf(
    key: string,
    attribute: string,
    elements: any[],
    condition: ((e: any) => boolean) | null = null,
  ) {
    return elements.reduce((acc: Set<string>, element: any) => {
      if (element.type === 'element' && element.name === key) {
        if (attribute in element.attributes) {
          if (
            condition === null ||
            (condition && condition(element.attributes))
          ) {
            acc.add(element.attributes[attribute]);
          }
        }
      }

      return acc;
    }, new Set());
  }

  private isIOWrite(e: any) {
    return e.action === 'IOWrite';
  }

  private isEvent(e: any) {
    return e.action === 'Event';
  }

  private appName(app: any) {
    return app.displayName
      ? `${app.displayName} [${app.pkgName}]`
      : app.pkgName;
  }

  private elementToXML(element: any) {
    const attributes = Object.entries(element.attributes)
      .map(([key, value]) => `${key}="${value}"`)
      .join(' ');

    return `<${element.name} ${attributes} />`;
  }

  private performanceInfo(row: any) {
    const perf = row.performances.find((e: any) => e.name === 'All');

    return `${perf.dTime.lighter ? '-' : '+'}${perf.dTime.value.toFixed(2)}s ${
      perf.mUsed.lighter ? '-' : '+'
    }${perf.mUsed.value.toFixed(2)}mb`;
  }

  private fill(key: string, kind: string, info: any[]) {
    return {
      key,
      kind,
      data: info.join(', '),
    };
  }
}
