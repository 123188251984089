
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import gql from 'graphql-tag';

import { mapState } from 'vuex';

import { analyze } from '#/analyses';
import {
  listApps,
  AppsGroup,
  listAppsGroups,
  createAppsGroup,
  deleteAppsGroup,
} from '#/apps';

import { ElectrumAuthVue } from '../../views/auth-vue';
import { Button } from 'ant-design-vue';
import { token } from '../../lib/local-storage';
import { APPS_SERVER } from '@/servers';

@Component
export default class ListAppsGroupsVue extends ElectrumAuthVue {
  private appsGroups: AppsGroup[] = [];
  private interval: any = null;
  private groupName: string = '';

  private readonly pageSize = 10;

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async created() {
    this.update();

    this.interval = setInterval(async () => {
      this.update();
    }, 20 * 1000);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  protected mounted() {
    this.updateTitle('Apps groups');
  }

  protected async update() {
    this.appsGroups = await listAppsGroups();
  }

  private async createGroup() {
    const groupName = this.groupName;

    // If group name is empty, we use a random name
    if (groupName) {
      await createAppsGroup(groupName);
    } else {
      await createAppsGroup();
    }

    this.update();
  }

  private async deleteAppsGroup(id: string) {
    await deleteAppsGroup(id);
    this.update();
  }
}
