import Axios from 'axios';

import { WALLPASS_SERVER } from '@/servers';

export async function generateToken(
  username: string,
  password: string,
): Promise<string | null> {
  let result: string | null = null;

  try {
    const response = await Axios.post<{ token: string }>(
      `${WALLPASS_SERVER}/generateToken`,
      {
        username,
        password,
      },
    );

    if (response.status === 201) {
      result = response.data.token;
    }
  } catch (e) {
    result = null;
  }

  return result;
}

export async function refreshToken(token: string): Promise<string | null> {
  let result: string | null = null;

  try {
    const response = await Axios.post<{ token: string }>(
      `${WALLPASS_SERVER}/refreshToken`,
      null,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    );

    if (response.status === 201) {
      result = response.data.token;
    }
  } catch (e) {
    result = null;
  }

  return result;
}

export async function verifyToken(token: string): Promise<boolean> {
  let result = false;

  try {
    const response = await Axios.get(`${WALLPASS_SERVER}/verifyToken`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    if (response.status === 200) {
      result = true;
    }
  } catch (e) {
    result = false;
  }

  return result;
}
