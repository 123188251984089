import { ElectrumVue } from './electrum-vue';

import { isWellConnected } from '@/lib/token';

export abstract class ElectrumAuthVue extends ElectrumVue {
  protected async beforeCreate() {
    if (!(await isWellConnected(this.$store))) {
      this.$router.push({
        name: 'login',
        query: {
          redirect: this.$route.path,
        },
      });
    }
  }
}
