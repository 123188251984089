import Axios from 'axios';

import { token } from '@/lib/local-storage';

import { KNOWLEDGE_SERVER } from '@/servers';
import { Report, Source, PermissionRequest } from './report.interface';

export interface Hardware {
  id: string;
  manufacturer: string;
  marketingName: string;
  model: string;
  device: string;
  product: string;
}

export interface OperatingSystem {
  id: string;
  name: string;
  codeName: string;
  version: string;
  developedBy: string;
  releaseDate: string;
}

export async function listHardwares(params?: {
  skip?: number;
  take?: number;
}): Promise<{
  count: number;
  results: Hardware[];
}> {
  return (
    await Axios.get<{ count: number; results: Hardware[] }>(
      `${KNOWLEDGE_SERVER}/hardwares`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params,
      },
    )
  ).data;
}

export async function listOperatingSystems(params?: {
  skip?: number;
  take?: number;
}): Promise<{
  count: number;
  results: OperatingSystem[];
}> {
  return (
    await Axios.get<{ count: number; results: OperatingSystem[] }>(
      `${KNOWLEDGE_SERVER}/operating-systems`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params,
      },
    )
  ).data;
}

export async function retrieveApplicationReportPermissions(
  id: string,
): Promise<PermissionRequest[]> {
  return mock.permissions;
}

export async function listApplicationReportAccesses(
  id: string,
): Promise<Source[]> {
  return mock.accesses;
}

const mock: Report = {
  id: 'foo',
  librairies: [],
  frameworks: [],
  accesses: [
    {
      data: {
        type: 'InstalledApplications',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function:
              'getApiVersion(Landroid/content/Context;)Ljava/lang/String;',
            class: 'Lnet/kdl/tesline01/Settings;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/content/pm/PackageManager;',
            property:
              'getPackageInfo(Ljava/lang/String;I)Landroid/content/pm/PackageInfo;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
    {
      data: {
        type: 'Geolocation',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'saveLocation(Landroid/location/Location;)V',
            class: 'Lnet/kdl/tesline01/KLService;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/location/Location;',
            property: 'getLatitude()D',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'checkLocation(Landroid/telephony/gsm/GsmCellLocation;)Z',
            class: 'Lnet/kdl/tesline01/GsmObserver;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/gsm/GsmCellLocation;',
            property: 'getCid()I',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'checkLocation(Landroid/telephony/gsm/GsmCellLocation;)Z',
            class: 'Lnet/kdl/tesline01/GsmObserver;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/gsm/GsmCellLocation;',
            property: 'getLac()I',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
    {
      data: {
        type: 'Network',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function:
              'onReceive(Landroid/content/Context;Landroid/content/Intent;)V',
            class: 'Lnet/kdl/tesline01/WifiReceiver;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/net/wifi/WifiInfo;',
            property: 'getSSID()Ljava/lang/String;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
    {
      data: {
        type: 'Message',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'run()V',
            class: 'Lnet/kdl/tesline01/SmsObserver$1$1;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/SmsMessage;',
            property: 'getOriginatingAddress()Ljava/lang/String;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'run()V',
            class: 'Lnet/kdl/tesline01/SmsObserver$1$1;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/SmsMessage;',
            property: 'getDisplayMessageBody()Ljava/lang/String;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'run()V',
            class: 'Lnet/kdl/tesline01/SmsObserver$1$1;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/SmsMessage;',
            property: 'createFromPdu([B)Landroid/telephony/SmsMessage;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
    {
      data: {
        type: 'NetworkOperator',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'run()V',
            class: 'Lnet/kdl/tesline01/KLService$19;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/TelephonyManager;',
            property: 'getNetworkCountryIso()Ljava/lang/String;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'run()V',
            class: 'Lnet/kdl/tesline01/KLService$19;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/TelephonyManager;',
            property: 'getNetworkOperatorName()Ljava/lang/String;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
    {
      data: {
        type: 'RunningApplications',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'isServiceRunning(Landroid/content/Context;)Z',
            class: 'Lnet/kdl/tesline01/MainActivity;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/app/ActivityManager;',
            property: 'getRunningServices(I)Ljava/util/List;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function: 'checkService()Z',
            class: 'Lnet/kdl/tesline01/TApplication;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/app/ActivityManager;',
            property: 'getRunningServices(I)Ljava/util/List;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
    {
      data: {
        type: 'Operator',
        attributes: {},
      },
      definitely: false,
      matches: [
        {
          definitely: false,
          location: {
            instruction: null,
            function:
              'onServiceStateChanged(Landroid/telephony/ServiceState;)V',
            class: 'Lnet/kdl/tesline01/GsmObserver;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/ServiceState;',
            property: 'getOperatorAlphaLong()Ljava/lang/String;',
            position: null,
            flow: false,
            subclass: null,
          },
        },
        {
          definitely: false,
          location: {
            instruction: null,
            function:
              'onServiceStateChanged(Landroid/telephony/ServiceState;)V',
            class: 'Lnet/kdl/tesline01/GsmObserver;',
            variable: null,
            line: null,
          },
          selector: {
            namespace: null,
            kind: 'Return',
            class: 'Landroid/telephony/ServiceState;',
            property: 'getState()I',
            position: null,
            flow: false,
            subclass: null,
          },
        },
      ],
    },
  ],
  modifications: [],
  deletions: [],
  leaks: [],
  permissions: [] as PermissionRequest[],
  components: [],
  communications: [],
  vulnerabilities: [],
  features: [],
  filesystem: null,
  database: [],
  performances: {},
};
