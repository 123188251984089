import { SinglePerf } from './performances/perf';

export class ReportPerformances {
  private performancesMap: Map<string, SinglePerf>;

  public constructor() {
    this.performancesMap = new Map();
    this.performancesMap.set(
      'Control flow analysis',
      new SinglePerf('Control flow analysis'),
    );
    this.performancesMap.set(
      'Loading extraneous',
      new SinglePerf('Loading extraneous'),
    );
    this.performancesMap.set('Loading app', new SinglePerf('Loading app'));
    this.performancesMap.set('Synthesis', new SinglePerf('Synthesis'));
    this.performancesMap.set('All', new SinglePerf('All'));
  }

  public addPerformance(perf: any) {
    switch (perf.Name) {
      case 'Fast analysis': {
        const val = this.performancesMap.get('Control flow analysis')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'InitModules': {
        const val = this.performancesMap.get('Loading extraneous')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'Load Rules': {
        const val = this.performancesMap.get('Loading extraneous')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'LoadContents': {
        const val = this.performancesMap.get('Loading app')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'Maestro loading': {
        const val = this.performancesMap.get('Loading extraneous')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'Maestro parsing': {
        const val = this.performancesMap.get('Loading extraneous')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'MakeStats': {
        const val = this.performancesMap.get('Synthesis')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'SourceAdder': {
        const val = this.performancesMap.get('Loading extraneous')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'Synthesis': {
        const val = this.performancesMap.get('Synthesis')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.DTime);
        break;
      }
      case 'all': {
        const val = this.performancesMap.get('All')!;
        val.addMUsed(perf.MUsed);
        val.addDTime(perf.Time);
        break;
      }
      default: {
        if (
          perf.Name.startsWith('Data (types-values-tags) Control Flow Analysis')
        ) {
          const val = this.performancesMap.get('Control flow analysis')!;
          val.addMUsed(perf.MUsed);
          val.addDTime(perf.DTime);
        }
        break;
      }
    }
  }

  public compare(perf: ReportPerformances) {
    const result = [];
    for (const key of this.performancesMap.keys()) {
      const val = this.performancesMap.get(key)!;
      result.push(val.compare(perf.performancesMap.get(key)!));
    }
    return result;
  }
}
