var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-table',{attrs:{"columns":[
    {
      title: 'Kind',
      dataIndex: 'kind',
    },
    {
      title: 'New',
      dataIndex: 'new',
    },
    {
      title: 'Lost',
      dataIndex: 'lost',
    },
    {
      title: 'Conserved',
      dataIndex: 'conserved',
    } ],"dataSource":[
    _vm.fill('data', 'Data', _vm.data),
    _vm.fill('destination', 'Destination', _vm.destination),
    _vm.fill('warning', 'Warning', _vm.warning),
    _vm.fill('network', 'Network', _vm.network),
    _vm.fill('libs', 'Libs', _vm.libs),
    _vm.fill('vulnerability', 'Vulnerabilities', _vm.vulnerability) ]}})}
var staticRenderFns = []

export { render, staticRenderFns }