































import { Vue, Component, Prop } from 'vue-property-decorator';

import { ping } from '#/index';

import { setInterval } from 'timers';

import { xml2js } from 'xml-js';

import xmlFormat from 'xml-formatter';

interface SummaryInfo {
  new: string[];
  lost: string[];
  conserved: string[];
}

@Component
export default class BenchmarkSummary extends Vue {
  @Prop(Object) private data!: SummaryInfo;
  @Prop(Object) private destination!: SummaryInfo;
  @Prop(Object) private event!: SummaryInfo;
  @Prop(Object) private warning!: SummaryInfo;
  @Prop(Object) private network!: SummaryInfo;
  @Prop(Object) private libs!: SummaryInfo;
  @Prop(Object) private vulnerability!: SummaryInfo;

  private fill(key: string, kind: string, info: SummaryInfo) {
    return {
      key,
      kind,
      new: info.new.join(', '),
      lost: info.lost.join(', '),
      conserved: info.conserved.join(', '),
    };
  }
}
