var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.appsGroup)?_c('div',[_c('h1',[_vm._v(_vm._s(_vm.appsGroup.name)+" ("+_vm._s(_vm.appsGroup.apps.length)+"apps)")]),_c('a-form',{attrs:{"layout":"inline"}},[_c('a-form-item',[_c('a-button',{attrs:{"disabled":_vm.disableAddRandom,"type":"primary"},on:{"click":function($event){return _vm.addRandom(10)}}},[_vm._v("Add randomly 10 apps")])],1),_c('a-form-item',[_c('a-button',{attrs:{"disabled":_vm.disableAddRandom,"type":"primary"},on:{"click":function($event){return _vm.addRandom(50)}}},[_vm._v("Add randomly 50 apps")])],1),_c('a-form-item',[_c('a-button',{attrs:{"disabled":_vm.disableAddRandom,"type":"primary"},on:{"click":function($event){return _vm.addRandom(100)}}},[_vm._v("Add randomly 100 apps")])],1),_c('a-form-item',[_c('a-button',{attrs:{"disabled":_vm.disableAddRandom,"type":"primary"},on:{"click":function($event){return _vm.addRandom(500)}}},[_vm._v("Add randomly 500 apps")])],1)],1),_c('a-slider',{attrs:{"min":1,"max":20},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}}),(_vm.appsGroup)?_c('a-list',{attrs:{"dataSource":_vm.appsGroup.apps,"pagination":{ pageSize: _vm.pageSize }},scopedSlots:_vm._u([{key:"renderItem",fn:function(app){return _c('a-list-item',{},[(_vm.links[app.id])?_c('a-button',{attrs:{"slot":"actions","icon":"download","shape":"circle","href":_vm.linkToDownloadApp(app.id)},slot:"actions"}):_c('a-button',{attrs:{"slot":"actions","icon":"cloud-download"},on:{"click":function($event){return _vm.downloadApp(app.id, app)}},slot:"actions"},[_vm._v("Generate a link for download")]),_c('a-list-item-meta',{attrs:{"description":("version " + (app.version) + ", sha1 " + (app.sha1))}},[_c('router-link',{attrs:{"slot":"title","to":{
              name: 'app',
              params: {
                package: app.pkgName,
                version: app.version,
                operatingSystem: app.osType,
                sha1: app.sha1,
              },
            }},slot:"title"},[_vm._v(_vm._s(app.displayName ? ((app.displayName) + " [" + (app.pkgName) + "]") : app.pkgName))]),(app.osType === 'Android')?_c('a-avatar',{staticStyle:{"backgroundcolor":"#87d068"},attrs:{"slot":"avatar","icon":"android"},slot:"avatar"}):_vm._e(),(app.osType === 'iOS')?_c('a-avatar',{staticStyle:{"backgroundcolor":"#bbbbbb"},attrs:{"slot":"avatar","icon":"apple"},slot:"avatar"}):_vm._e()],1)],1)}}],null,false,1873741350)}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }