import { MatchDestination } from './match-destination';

export class MatchAction {
  private mapMatchByAction: Map<string, MatchDestination>;
  private arrayOfActions: string[];

  public constructor() {
    this.mapMatchByAction = new Map();
    this.arrayOfActions = [];
  }

  public addMatch(match: any) {
    const matchAction = match.attributes.action;
    // MatchDestination was already created
    if (this.mapMatchByAction.has(matchAction)) {
      const matchDest = this.mapMatchByAction.get(matchAction);
      if (matchDest) {
        matchDest.addMatch(match);
      }
    } else {
      // Create new MapDestination and add the new action to the list
      const newDestination = new MatchDestination();
      newDestination.addMatch(match);
      this.arrayOfActions.push(matchAction);
      this.mapMatchByAction.set(matchAction, newDestination);
    }
  }

  public getMatches(action: string) {
    return this.mapMatchByAction.get(action);
  }

  public getActions() {
    return this.arrayOfActions;
  }

  public sort() {
    this.arrayOfActions.sort((n1: string, n2: string) =>
      this.stringComparator(n1, n2),
    );
    for (const action of this.arrayOfActions) {
      const match = this.mapMatchByAction.get(action);
      if (match) {
        match.sort();
      }
    }
  }

  public compare(report: MatchAction) {
    let i = 0;
    let j = 0;

    const result = new Map();

    const deleted = [];
    const added = [];
    const included = [];

    while (i < this.arrayOfActions.length && j < report.arrayOfActions.length) {
      const resAction = this.stringComparator(
        this.arrayOfActions[i],
        report.arrayOfActions[j],
      );

      switch (resAction) {
        case -1: {
          // All match of the same action aren't in the new report
          const dest = this.mapMatchByAction.get(this.arrayOfActions[i]);
          if (dest) {
            for (const matchTmp of dest.getAllMatches()) {
              deleted.push(matchTmp);
            }
          }
          ++i;
          break;
        }
        case 0: {
          const thisDest = this.mapMatchByAction.get(this.arrayOfActions[i]);
          const reportDest = report.mapMatchByAction.get(
            report.arrayOfActions[j],
          );

          if (thisDest && reportDest) {
            const resTmp = thisDest.compare(reportDest);

            // copy all the results
            for (const deleteTmp of resTmp.get('deleted')) {
              deleted.push(deleteTmp);
            }

            for (const addTmp of resTmp.get('added')) {
              added.push(addTmp);
            }

            for (const includTmp of resTmp.get('included')) {
              included.push(includTmp);
            }
          }

          ++i;
          ++j;
          break;
        }
        case 1: {
          // All match of the same action aren't in the old report
          const dest = report.mapMatchByAction.get(report.arrayOfActions[j]);
          if (dest) {
            for (const matchTmp of dest.getAllMatches()) {
              added.push(matchTmp);
            }
          }

          ++j;
          break;
        }
      }
    }

    // Add the last elements when we finished analyzing a report
    if (i < this.arrayOfActions.length) {
      for (let itmp = i; itmp < this.arrayOfActions.length; ++itmp) {
        const dest = this.mapMatchByAction.get(this.arrayOfActions[itmp]);
        if (dest) {
          for (const matchTmp of dest.getAllMatches()) {
            deleted.push(matchTmp);
          }
        }
      }
    }

    if (j < report.arrayOfActions.length) {
      for (let jtmp = j; jtmp < report.arrayOfActions.length; ++jtmp) {
        const dest = report.mapMatchByAction.get(report.arrayOfActions[jtmp]);
        if (dest) {
          for (const matchTmp of dest.getAllMatches()) {
            added.push(matchTmp);
          }
        }
      }
    }

    // for (const del of deleted) {
    //   console.log('<deleted>' + JSON.stringify(del));
    // }

    // for (const addTmp of added) {
    //   console.log('<added>' + JSON.stringify(addTmp));
    // }

    // for (const inc of included) {
    //   console.log('<included>' + JSON.stringify(inc));
    // }
    // Push values in the Map
    result.set('deleted', deleted);
    result.set('added', added);
    result.set('included', included);
    return result;
  }

  // Return 0 if equal, -1 if string1 < string2 and 1 if string1 > string2
  private stringComparator(string1: string, string2: string) {
    if (string1 === string2) {
      return 0;
    }
    if (string1 < string2) {
      return -1;
    }
    return 1;
  }
}
