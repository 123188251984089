

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { ElectrumAuthVue } from '../auth-vue';

import { token } from '@/lib/local-storage';
import { IdentifyingQueue } from '@/lib/identifying-queue';

import { uploadStatus } from '#/upload';

import gql from 'graphql-tag';
import { describe } from 'mocha';
import { setInterval, clearInterval } from 'timers';
import { UPLOAD_SERVER } from '../../servers';

enum State {
  identifying = 0,
  identified = 1,
  unindentified = 2,
}

@Component
export default class UploadVue extends ElectrumAuthVue {
  private fileList = [];

  private uploadURL = `${UPLOAD_SERVER}/uploadApp`;

  private interval: any;
  private headers: any;

  // tslint:disable-next-line
  private queue = new (class extends IdentifyingQueue {
    constructor(private readonly self: UploadVue) {
      super();
    }

    protected onSuccess(name: string) {
      this.self.$message.success(`${name} file successfully identified.`);
    }

    protected onFail(name: string) {
      this.self.$message.error(`${name} is not a correct application.`);
    }
  })(this);

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected created() {
    this.updateTitle('Upload an application');

    this.headers = {
      Authorization: `bearer ${token()}`,
    };

    this.queue.start();
  }

  protected detroyed() {
    this.queue.stop();
  }

  private handleChange(info: any) {
    const status = info.file.status;

    if (status === 'done') {
      const response = info.file.response;

      this.queue.enqueue({
        name: info.file.name,
        taskId: response.taskId,
      });
    } else if (status === 'error') {
      this.$message.error(`${info.file.name} file upload failed.`);
    }
  }
}
