













import { Component } from 'vue-property-decorator';

import { ElectrumAuthVue } from '../auth-vue';

import ListApps from '@/components/sense/ListApps.vue';
import ListAppsGroups from '@/components/sense/ListAppsGroups.vue';

@Component({ components: { ListApps, ListAppsGroups } })
export default class ListAppsVue extends ElectrumAuthVue {
  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected mounted() {
    this.updateTitle('Apps');
  }
}
