import { PerfResult } from './perf-result';

export class SinglePerf {
  private mUsed: string[];
  private mUsedSum: number;
  private dTime: string[];
  private dTimeSum: number;
  private name: string;

  public constructor(nameValue: string) {
    this.mUsed = [];
    this.dTime = [];
    this.mUsedSum = 0;
    this.dTimeSum = 0;
    this.name = nameValue;
  }

  public addMUsed(mUsedValue: string) {
    this.mUsed.push(mUsedValue);
    this.mUsedSum = this.mUsedSum + Number(mUsedValue);
  }

  public addDTime(dTimeValue: string) {
    this.dTime.push(dTimeValue);
    this.dTimeSum = this.dTimeSum + Number(dTimeValue);
  }

  public compare(single: SinglePerf) {
    const result = new PerfResult(this.name);

    const resDTime = single.dTimeSum - this.dTimeSum;
    const resMUsed = single.mUsedSum - this.mUsedSum;
    if (resDTime > 0) {
      result.dTime.lighter = false;
    }
    if (resMUsed > 0) {
      result.mUsed.lighter = false;
    }

    result.dTime.value = resDTime;
    result.mUsed.value = resMUsed;

    return result;
  }
}
