

































import { Vue, Component } from 'vue-property-decorator';

import {
  Crawler,
  CrawlerTrackingEntry,
  listCrawlers,
  listCrawlerTrackingEntries,
} from '#/crawler';

import moment from 'moment';

@Component({
  filters: {
    showDate(date: string) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
  },
})
export default class ListCrawlerTrackingEntriesVue extends Vue {
  private page: number = 1;
  private count = 0;
  private crawlers: Crawler[] = [];
  private entries: CrawlerTrackingEntry[] = [];
  private selectedCrawler: null | string = null;
  private interval: any = null;

  private readonly pageSize = 10;

  protected async created() {
    this.crawlers = await listCrawlers();

    this.interval = setInterval(async () => {
      this.crawlers = await listCrawlers();

      await this.refreshEntries();
    }, 20 * 1000);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private async selectCrawler(crawler: string) {
    this.selectedCrawler = crawler;

    await this.refreshEntries();
  }

  private async refreshEntries() {
    if (this.selectedCrawler) {
      const { count, entries } = await listCrawlerTrackingEntries(
        this.selectedCrawler,
        this.pageSize,
        (this.page - 1) * this.pageSize,
        false,
      );

      this.count = count;
      this.entries = entries;
    }
  }
}
