
















import { Component } from 'vue-property-decorator';

import { ElectrumAuthVue } from '../auth-vue';

import ListAnalyses from '@/components/mind/ListAnalyses.vue';
import ListEngines from '@/components/mind/ListEngines.vue';
import ListTags from '@/components/mind/ListTags.vue';

@Component({ components: { ListAnalyses, ListEngines, ListTags } })
export default class AnalysesVue extends ElectrumAuthVue {
  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected mounted() {
    this.updateTitle('Analyses');
  }
}
