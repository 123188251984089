








import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import AnalyseVue from '@/components/Analyse.vue';

import { mapState } from 'vuex';

import { ElectrumVue, Menus } from './electrum-vue';

@Component
export default class SettingsVue extends ElectrumVue {
  private current: string[] = ['mail'];

  private showModal: boolean = true;

  public async created() {
    this.showModal = this.$store.getters['settings/showModal'];
  }

  public async mounted() {
    this.updateTitle('Settings');
  }

  private showModalDialog(e: any) {
    const checked: boolean = e.target.checked;

    this.$store.commit('settings/setShowModal', String(checked));
  }
}
