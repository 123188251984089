var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('a-col',{attrs:{"span":_vm.isMobileApp ? 12 : 4}},[_c('img',{staticStyle:{"width":"80%","margin-left":"auto","margin-right":"auto","display":"block"},attrs:{"src":"/assets/images/logo5.png"}}),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                { required: true, message: 'Please input your username!' } ],
            } ]),expression:"[\n            'username',\n            {\n              rules: [\n                { required: true, message: 'Please input your username!' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Please input your password!' } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Please input your password!' },\n              ],\n            },\n          ]"}],attrs:{"type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),(_vm.oneFailTry)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" Your credential are not correct ")]):_vm._e(),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Log in")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }