






















import { Vue, Component } from 'vue-property-decorator';

import { Engines, listEngines, Engine } from '#/analysis';

import { formatEngineName } from '@/lib/analysis';

@Component({
  filters: {
    formatEngineName: (engine: Engine) => {
      return formatEngineName(engine);
    },
  },
})
export default class ListEnginesVue extends Vue {
  private engines: Engines = {};
  private interval: any = null;

  protected async created() {
    this.engines = await listEngines();

    this.interval = setInterval(async () => {
      this.engines = await listEngines();
    }, 20 * 1000);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
