


































import { Component } from 'vue-property-decorator';

import { Tag, listTags, createTag, deleteTag } from '#/analysis';

import { ElectrumAuthVue } from '../../views/auth-vue';

import Output from '@/components/analysis/Output.vue';
import Vue from 'vue';

@Component({
  components: {
    Output,
  },
})
export default class ListTagsVue extends Vue {
  private tags: Tag[] = [];
  private tagName: string = '';
  private interval: any = null;

  private readonly pageSize = 10;

  protected async created() {
    this.update();

    this.interval = setInterval(async () => {
      this.update();
    }, 20 * 1000);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private async update() {
    this.tags = await listTags();
  }

  private async createTag() {
    if (this.tagName) {
      await createTag(this.tagName);
      await this.update();
    }
  }

  private async deleteTag(id: string) {
    await deleteTag(id);
    await this.update();
  }
}
