import Axios from 'axios';

import { token } from '@/lib/local-storage';

import { CRAWLER_SERVER } from '@/servers';

export interface Crawler {
  pid: number;
  id: string;
  name: string;
  entrypoint: string;
  engine: string;
  parameters: any;
  active: boolean;
}

export interface CrawlerTrackingEntry {
  id: number;
  status: string;
  currentlyVisited: string;
  message: string | null;
  date: string;
}

export async function listCrawlers(): Promise<Crawler[]> {
  return (
    await Axios.get<Crawler[]>(`${CRAWLER_SERVER}/listCrawlers`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function listCrawlerTrackingEntries(
  crawler: string,
  take: number,
  skip: number,
  ascending: boolean,
): Promise<{
  count: number;
  entries: CrawlerTrackingEntry[];
}> {
  return (
    await Axios.get<{ count: number; entries: CrawlerTrackingEntry[] }>(
      `${CRAWLER_SERVER}/listCrawlerTrackingEntries`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params: {
          take,
          skip,
          ascending,
          crawler,
        },
      },
    )
  ).data;
}
