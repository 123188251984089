














































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { ElectrumAuthVue } from '../auth-vue';

import { retrieveXMLAppReport, retrieveAppReport } from '#/repertoire';

import xmlFormat from 'xml-formatter';
import { ElectrumVue } from '../electrum-vue';

import {
  Analyses,
  listAnalyses,
  pendingAnalyses,
  Engine,
  retrieveAnalysis,
  Analysis,
  AnalysisOverview,
} from '#/analysis';

import { formatEngineName } from '../../lib/analysis';

import { js2xml } from 'xml-js';

@Component({
  filters: {
    formatEngineName: (engine: Engine) => {
      return formatEngineName(engine);
    },
  },
})
export default class AnalysisVue extends ElectrumAuthVue {
  private id: string | null = null;
  private welcome = true;
  private retrieved = false;
  private notAvailable = false;
  private report: any | null = null;
  private xmlFormat = xmlFormat;
  private js2xml = js2xml;
  private analysis: Analysis | null = null;
  private interval: any = null;

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async mounted() {
    this.id = this.$route.params.id;

    this.updateTitle(`Analysis ${this.id}`);

    await this.refresh();
    this.welcome = false;

    if (!this.notAvailable) {
      this.interval = setInterval(this.refresh.bind(this), 2000);
    }
  }

  protected async refresh() {
    const retrievedReport = await retrieveAppReport(this.id!);

    const retrieved = retrievedReport.retrieved;
    let report = null;

    if (retrieved) {
      if (this.interval) {
        clearInterval(this.interval);
      }

      report = retrievedReport.report;

      if (typeof report === 'string') {
        report = JSON.parse(report);
      }

      if (report === null) {
        this.notAvailable = true;
      }
    } else {
      this.analysis = await retrieveAnalysis(this.id!);

      if (Object.keys(this.analysis).length === 1) {
        this.notAvailable = true;
      }
    }

    this.retrieved = retrieved;
    this.report = report;
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private get rows() {
    return this.report.elements[0].elements;
  }

  private byName(name: string) {
    return (e: any) => e.name === name;
  }

  private byAction(action: string) {
    return (e: any) => e.name === 'Match' && e.attributes.action === action;
  }

  private byDestination(destination: string) {
    return (e: any) =>
      e.name === 'Match' && e.attributes.destination === destination;
  }

  private byDestinations(...destinations: string[]) {
    return (e: any) =>
      e.name === 'Match' &&
      destinations.indexOf(e.attributes.destination) !== -1;
  }

  private cleanAttributesDisplay(attributes: any) {
    const cleanAttributes: any = {};

    for (let [key, value] of Object.entries(attributes)) {
      switch (key) {
        case 'destination':
        case 'data':
        case 'action':
        case 'nf_action':
        case 'id':
          continue;
        case 'd_process_info':
          key = 'Information sur les processus';
          break;
        case 'd_info_kind':
          key = "Type d'information";
          break;
        case 'd_property_name':
          key = 'Nom de la propriété';
          break;
        case 'd_filelocation':
          key = 'Localisation du fichier';
          break;
        case 'd_file_name':
          key = 'Nom du fichier';
          break;
        case 'd_prefix':
          key = 'Prefixe du nom de fichier';
          break;
        case 'd_suffix':
          key = 'Suffixe du nom de fichier';
          break;
        case 'filelocation':
          key = 'Localisation du fichier de destination';
          break;
        case 'file_name':
          key = 'Nom du fichier de destination';
          break;
      }

      switch (value) {
        case 'application_files_default_folder':
          value = "Dossier par défaut des fichiers de l'application";
          break;
        case 'application_cache_folder':
          value = "Dossier de cache des fichiers de l'application";
          break;
        case 'SYSTEM_PROPERTY':
          value = 'Propriété système';
          break;
      }

      value = (value as string).replace(/\(-\?-\)/g, '*');

      cleanAttributes[key] = value;
    }

    return Object.keys(cleanAttributes).length === 0
      ? ''
      : JSON.stringify(cleanAttributes);
  }

  private dataDisplay(data: string) {
    if (!data) {
      return 'Quelque chose';
    }

    switch (data) {
      case 'SystemInfo':
        return 'Information système';
      case 'OsVersionInfo':
        return "Information de version du système d'exploitation";
      case 'UserTextInfo':
        return "Information d'un champs texte";
      case 'FileInfo':
        return 'Information du système de fichier';
      case 'ActivitiesExecInfo':
        return "Information sur l'exécution des activités";
      case 'StackTraceElementInfo':
        return "Information sur la pile d'exécution";
      case 'ThreadInfo':
        return 'Information sur les processus léger (thread)';
      case 'ApplicationInfo':
        return "Information sur l'application";
      case 'FileCacheInfo':
        return 'Information mise en cache';
      case 'LoginInfo_txt':
        return 'Information sur un champs';
      case 'OsProcessInfo':
        return 'Information sur les processus';
    }

    return data;
  }

  private filesystemAction(data: string) {
    switch (data) {
      case 'write':
        return 'Ecriture';
      case 'delete':
        return 'Suppresion';
      case 'create':
        return 'Création';
      case 'read':
        return 'Lecture';
    }

    return data;
  }

  protected formatAnalysisStatus(status: string) {
    let result = '';

    switch (status) {
      case 'TODO':
        result = 'Waiting for available runner';
        break;
      case 'Downloading':
        result = 'Downloading app before analysis';
        break;
      case 'WIP':
        result = 'Analysis in progress';
        break;
      default:
        result = 'Terminated';
    }

    return result;
  }

  protected formatTime(time: number) {
    let suffix = 'ms';

    if (time > 1000) {
      time /= 1000;
      suffix = 's';
    }

    if (time > 60) {
      time /= 60;
      suffix = 'm';
    }

    if (time > 60) {
      time /= 60;
      suffix = 'h';
    }

    return `${time.toFixed(2)}${suffix}`;
  }

  protected formatMemory(memory: number) {
    let suffix = 'b';

    if (memory > 1024) {
      memory /= 1024;
      suffix = 'kb';
    }

    if (memory > 1204) {
      memory /= 1024;
      suffix = 'mb';
    }

    if (memory > 1024) {
      memory /= 1024;
      suffix = 'gb';
    }

    return `${memory.toFixed(2)}${suffix}`;
  }
}
