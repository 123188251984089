

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { getAnalysisStatus } from '#/analyses';

@Component
export default class ProgressionVue extends Vue {
  @Prop(String) private readonly data!: string;

  private status: number = 0;

  protected async mounted() {
    this.update();
  }

  @Watch('data')
  private update() {
    const data = this.data;

    let status = 0;

    if (data.indexOf('LoadContents started') > 0) {
      status = 1;
    }

    if (data.indexOf('Load Rules started') > 0) {
      status = 2;
    }

    if (
      data.match(
        /Data \(types-values-tags\) Control Flow Analysis \(k=\d+\) started/gi,
      ) !== null
    ) {
      status = 3;
    }

    if (data.indexOf('Synthesis started') > 0) {
      status = 4;
    }

    if (data.indexOf('Everything finished') > 0) {
      status = 5;
    }

    this.status = status;
  }
}
