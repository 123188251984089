














































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { requestNewAnalysis, requestTrustRevealingAnalysis } from '#/analysis';

import ListAnalysesForApp from '@/components/apps/ListAnalysesForApp.vue';
import ListImageRecognitionTagsForApp from '@/components/apps/ListImageRecognitionTagsForApp.vue';

import { ElectrumAuthVue } from '../auth-vue';
import {
  AppsGroup,
  listAppsGroups,
  addAppToGroup,
  retrieveApp,
  AppRetrieveInfo,
} from '#/apps';

@Component({
  components: {
    ListAnalysesForApp,
    ListImageRecognitionTagsForApp,
  },
})
export default class AppVue extends ElectrumAuthVue {
  private app: AppRetrieveInfo | null = null;
  private appsGroups: AppsGroup[] = [];
  private selectedAppGroup: string = '';
  private analysisRequests: any[] = [];
  private buttonText = 'Analyse this app';

  private package: string | null = null;
  private operatingSystem: string | null = null;
  private version: string | null = null;
  private sha1: string | null = null;

  private disabled = false;
  private k: number = 1;
  private min = 0;
  private max = 25;

  get marks() {
    const result: any = {};

    for (let i = this.min; i <= this.max; i++) {
      result[i] = String(i);
    }

    return result;
  }

  get reversedAnalysisRequests() {
    return this.analysisRequests.reverse();
  }

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async mounted() {
    this.updateTitle('Application info');

    this.app = await retrieveApp({
      pkgName: this.$route.params.package,
      osType: this.$route.params.operatingSystem,
      version: this.$route.params.version,
      sha1: this.$route.params.sha1,
    });

    this.appsGroups = await listAppsGroups();
  }

  private async magic(appid: number) {
    // if (this.id) {
    //   this.$router.push({
    //     name: 'analysis-app',
    //     params: {
    //       id: this.id,
    //     },
    //   });
    // } else {
    //   this.disabled = true;
    //   const response = await analyze(appid, this.k);
    //   if (response.status !== 201) {
    //     return this.$message.error('An error append, analysis was canceled');
    //   }
    //   this.disabled = false;
    //   this.id = response.data.id;
    //   this.buttonText = 'Access the analysis';
    // }
  }

  private async requestNewAnalysis() {
    if (this.app) {
      await requestNewAnalysis({
        ...this.app,
        tags: 'production',
        priority: 50,
      });

      (this.$refs.analyses as ListAnalysesForApp).refresh();
    }
  }

  private async requestTrustRevealingAnalysis() {
    if (this.app) {
      await requestTrustRevealingAnalysis({
        package: this.app.pkgName,
        version: this.app.version,
        sha1: this.app.sha1,
        system: this.app.osType,
      });
    }
  }

  private addToGroup() {
    if (this.selectedAppGroup) {
      addAppToGroup(this.app!.id, this.selectedAppGroup);
    }
  }
}
