





















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import {
  listCategorizedURL,
  CategorizedURL,
  addCategorizedURL,
} from '#/repertoire';

import { ElectrumAuthVue } from '../auth-vue';

import { confirm } from '@/lib/util';

import moment from 'moment';

@Component({
  filters: {
    date: (date: Date) => {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
  },
})
export default class ListCategorizedURLVue extends ElectrumAuthVue {
  private page: number = 1;
  private count = 0;
  private results: CategorizedURL[] = [];
  private from: string | null = null;
  private to: string | null = null;

  private protocolToAdd: string = 'http:';
  private urlToAdd?: string;
  private kindToAdd: string = 'domain';
  private categoryToAdd: string = 'phishing';

  private readonly moment = moment;

  private readonly pageSize = 20;

  private readonly formatDate = (date: string) =>
    moment(date).format('MMMM Do YYYY, h:mm:ss a');

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async created() {
    await this.refreshResults();
  }

  protected mounted() {
    this.updateTitle('Categorized URL');
  }

  private async add() {
    const { kindToAdd, protocolToAdd, urlToAdd, categoryToAdd } = this;

    if (urlToAdd?.length ?? 0 > 0) {
      await confirm(this, {
        title: 'You are about to add an entry into phishing',
        content: `If you confirm, you will add the ${kindToAdd} <<${protocolToAdd}//${urlToAdd}>> into ${categoryToAdd} category`,
        onOk: async () => {
          await addCategorizedURL({
            kind: kindToAdd,
            url: urlToAdd!,
            protocol: protocolToAdd,
            category: categoryToAdd,
          });
        },
        onCancel: async () => {},
      });
    }
  }

  private async pickRange(dates: [moment.Moment, moment.Moment]) {
    this.from = dates[0].toISOString();
    this.to = dates[1].toISOString();

    await this.refreshResults();
  }

  private async refreshResults() {
    const { count, results } = await listCategorizedURL({
      take: this.pageSize,
      skip: (this.page - 1) * this.pageSize,
      from: this.from ?? undefined,
      to: this.to ?? undefined,
    });

    this.count = count;
    this.results = results;
  }

  @Watch('page')
  private async newPage() {
    await this.refreshResults();
  }
}
