










import { Component } from 'vue-property-decorator';

import { ElectrumAuthVue } from '../auth-vue';

import ReportsSynthesis from '@/components/mind/ReportsSynthesis.vue';

@Component({ components: { ReportsSynthesis } })
export default class ApplicationReportsVue extends ElectrumAuthVue {
  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected mounted() {
    this.updateTitle('Reports');
  }
}
