import Vue from 'vue';
import Router from 'vue-router';

import App from './views/sense/App.vue';

import ListAppsVue from './views/sense/ListApps.vue';
import CrawlersVue from './views/sense/Crawlers.vue';
import KnowledgeVue from './views/sense/Knowledge.vue';
import AppsGroupVue from './views/sense/AppsGroup.vue';
import ListCategorizedURLVue from './views/sense/ListCategorizedURL.vue';

import ReportsSynthesisVue from './components/mind/ReportsSynthesis.vue';
import AnalysesVue from './views/mind/Analyses.vue';
import ApplicationReportsVue from './views/mind/ApplicationReports.vue';
import ApplicationReportVue from './views/mind/ApplicationReport.vue';

import ListAppleAppStoreRequestsVue from './views/vault/ListAppleAppStoreRequests.vue';
import UploadVue from './views/vault/Upload.vue';

import AnalysisVue from './views/analysis/Analysis.vue';
import BenchmarkVue from './views/mind/Benchmark.vue';

import ListHardwares from './components/sense/ListHardwares.vue';
import ListOperatingSystems from './components/sense/ListOperatingSystems.vue';

import LoginVue from './views/Login.vue';
import SettingsVue from './views/Settings.vue';
import NotAvailableVue from './views/NotAvailable.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/analysis/:id',
      name: 'analysis-app',
      redirect: '/analysis/:id/output',
    },
    {
      path: '/apps',
      name: 'apps',
      component: ListAppsVue,
    },
    {
      path: '/apps/:operatingSystem/:package/:version/:sha1',
      name: 'app',
      component: App,
    },
    {
      path: '/sense/reports-synthesis',
      name: 'sense-reports-synthesis',
      component: ReportsSynthesisVue,
    },
    {
      path: '/store',
      name: 'store',
      component: NotAvailableVue,
    },
    {
      path: '/apple-store-requests',
      name: 'apple-store-requests',
      component: ListAppleAppStoreRequestsVue,
    },
    {
      path: '/categorized-url',
      name: 'categorized-url',
      component: ListCategorizedURLVue,
    },
    {
      path: '/analyses',
      name: 'analyses',
      component: AnalysesVue,
    },
    {
      path: '/application-reports',
      name: 'application-reports',
      component: ApplicationReportsVue,
    },
    {
      path: '/analyses/:id',
      name: 'analysis',
      component: AnalysisVue,
    },
    {
      path: '/application-reports/:id',
      name: 'application-report',
      component: ApplicationReportVue,
    },
    {
      path: '/apps-group/:id',
      name: 'apps-group',
      component: AppsGroupVue,
    },
    {
      path: '/benchmarks/:id',
      name: 'benchmark',
      component: BenchmarkVue,
    },
    {
      path: '/upload',
      name: 'upload',
      component: UploadVue,
    },
    {
      path: '/crawlers',
      name: 'crawlers',
      component: CrawlersVue,
    },
    {
      path: '/knowledge',
      name: 'knowledge',
      component: KnowledgeVue,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginVue,
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsVue,
    },
    {
      path: '/not-available',
      name: 'not-available',
      component: NotAvailableVue,
    },
    {
      path: '*',
      redirect: {
        name: 'apps',
      },
    },
  ],
});
