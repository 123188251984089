// tslint:disable:no-shadowed-variable

interface IState {
  count: number;
}

export const state: IState = {
  count: 0,
};

export const mutations = {
  increment(state: IState) {
    // `state` is the local module state
    state.count++;
  },
};
