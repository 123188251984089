import Axios from 'axios';

import { token } from '@/lib/local-storage';

import { ANALYSIS_SERVER, TRUSTREVEALING_SERVER } from '@/servers';

export interface Engine {
  id: string;
  user: string;
  tags: string[];
  name: string;
  availableQueues: number;
}

export interface Engines {
  [id: string]: Engine;
}

export interface AnalysisOverview {
  id: string;
  status: string;
}

export interface Analysis extends AnalysisOverview {
  elapsed: number;
  elapsedLimit: number;
  memory: number;
  memoryLimit: number;
  output: string;
  engine: any;
  tags: string[];
}

export interface Analyses {
  [id: string]: Analysis;
}

export interface Tag {
  id: string;
}

export async function listEngines(): Promise<Engines> {
  return (
    await Axios.get<Engines>(`${ANALYSIS_SERVER}/listEngines`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function listAnalyses(): Promise<AnalysisOverview[]> {
  return (
    await Axios.get<AnalysisOverview[]>(`${ANALYSIS_SERVER}/listAnalyses`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function pendingAnalyses(): Promise<AnalysisOverview[]> {
  return (
    await Axios.get<AnalysisOverview[]>(`${ANALYSIS_SERVER}/pendingAnalyses`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function retrieveAnalysis(id: string): Promise<Analysis> {
  return (
    await Axios.get<Analysis>(`${ANALYSIS_SERVER}/retrieveAnalysis`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
      params: {
        id,
      },
    })
  ).data;
}

export async function listAnalysesForApp(appId: number): Promise<any[]> {
  return (
    await Axios.get<any[]>(`${ANALYSIS_SERVER}/listAnalysesForApp`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
      params: {
        appId,
      },
    })
  ).data;
}

export async function listTags(): Promise<Tag[]> {
  return (
    await Axios.get<Tag[]>(`${ANALYSIS_SERVER}/listTags`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}

export async function createTag(id: string): Promise<void> {
  await Axios.post(
    `${ANALYSIS_SERVER}/createAnalysisTag`,
    { id },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function deleteTag(id: string): Promise<void> {
  await Axios.post(
    `${ANALYSIS_SERVER}/deleteAnalysisTag`,
    { id },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function lookFor(params: {
  pkgName: string;
  version: string;
  sha1: string;
  osType: string;
  take?: number;
  skip?: number;
}): Promise<{ count: number; results: Analysis[] }> {
  return (
    await Axios.get<{ count: number; results: Analysis[] }>(
      `${ANALYSIS_SERVER}/lookFor`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params,
      },
    )
  ).data;
}

export async function requestNewAnalysis(params: {
  pkgName: string;
  version: string;
  sha1: string;
  osType: string;
  tags: string[] | string;
  priority?: number;
}): Promise<void> {
  if (Array.isArray(params.tags)) {
    params.tags = params.tags.join(',');
  }

  await Axios.post(`${ANALYSIS_SERVER}/requestNewAnalysis`, params, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
}

export async function requestTrustRevealingAnalysis(params: {
  package: string;
  version: string;
  sha1: string;
  system: string;
}): Promise<void> {
  await Axios.post(`${TRUSTREVEALING_SERVER}/requestAnalysis`, params, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
}
