// tslint:disable:no-shadowed-variable

import { ActionTree, Commit } from 'vuex';

function localStorageOr(key: string, defaultValue: string) {
  let result = localStorage.getItem(key);

  if (result === null) {
    result = defaultValue;
  }

  return result;
}

export const namespaced = true;

// tslint:disable-next-line: no-empty-interface
interface IState {
  showModal: string;
}

export const state: IState = {
  showModal: localStorageOr('showModal', 'false'),
};

export const getters = {
  showModal(state: IState): boolean | null {
    return Boolean(JSON.parse(state.showModal));
  },
};

export const actions: ActionTree<any, Record<string, unknown>> = {};

export const mutations = {
  setShowModal(state: IState, showModal: 'false' | 'true') {
    localStorage.setItem('showModal', showModal);
    state.showModal = showModal;
  },
};
