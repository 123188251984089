

















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { addAppToGroup, App, AppsGroup, listApps, searchApps } from '#/apps';

import { confirm } from '@/lib/util';

import { token } from '../../lib/local-storage';
import { APPS_SERVER } from '@/servers';

@Component
export default class ListApps extends Vue {
  private page: number = 1;
  private count = 0;
  private apps: App[] = [];
  private appsGroups: AppsGroup[] = [];
  private links: string[] = [];
  private disabled = new Set<number>();
  private interval: any = null;
  private searchVisible = true;

  private readonly pageSize = 10;

  private searchMode = false;
  private searchPackage = '';
  private searchPackageMatchMethod = 'equals';
  private searchVersion = '';
  private searchVersionMatchMethod = 'equals';
  private searchSha1 = '';
  private searchSha1MatchMethod = 'equals';
  private searchSystem = 'any';

  protected async created() {
    await this.refreshApps();
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  protected linkToDownloadApp(index: number) {
    return `${APPS_SERVER}/downloadAppWithLink?id=${this.links[index]}`;
  }

  get appsByOS() {
    return this.apps.reduce((result: any, app: any) => {
      if (app.osType in result) {
        result[app.osType].push(app);
      } else {
        result[app.osType] = [app];
      }

      return result;
    }, {});
  }

  private async refreshApps() {
    if (this.searchMode) {
      const search: any = {};

      if (this.searchPackage !== '') {
        search.pkgName = this.searchPackage;
        search.pkgNameMatchMethod = this.searchPackageMatchMethod;
      }

      if (this.searchVersion !== '') {
        search.version = this.searchVersion;
        search.versionMatchMethod = this.searchVersionMatchMethod;
      }

      if (this.searchSha1 !== '') {
        search.sha1 = this.searchSha1;
        search.sha1MatchMethod = this.searchSha1MatchMethod;
      }

      if (this.searchSystem !== 'any') {
        search.osType = this.searchSystem;
      }

      const { count, apps } = await searchApps({
        take: this.pageSize,
        skip: (this.page - 1) * this.pageSize,
        ascending: false,
        ...search,
      });

      this.count = count;
      this.apps = apps;
    } else {
      const { count, apps } = await listApps(
        this.pageSize,
        (this.page - 1) * this.pageSize,
        false,
      );

      this.count = count;
      this.apps = apps;
    }
  }

  @Watch('page')
  private async newPage() {
    await this.refreshApps();
  }

  private async search() {
    this.searchMode =
      this.searchPackage !== '' ||
      this.searchVersion !== '' ||
      this.searchSha1 !== '';

    await this.refreshApps();
  }

  private async clearSearch() {
    this.searchMode = false;
    this.searchPackage = '';
    this.searchPackageMatchMethod = 'equals';
    this.searchVersion = '';
    this.searchVersionMatchMethod = 'equals';
    this.searchSha1 = '';
    this.searchSha1MatchMethod = 'equals';
    this.searchSystem = 'any';

    await this.refreshApps();
  }

  private async addAppToGroup(data: string, element: Vue) {
    const parsed = JSON.parse(data);
    const { app, appsGroup } = parsed;

    console.log(element as any);

    try {
      await confirm(this, {
        title: `Your about to add an application (${
          app.displayName !== '' ? app.displayName : app.pkgName
        }) to group ${appsGroup.name}`,
        content: '',
        onOk: async () => {
          // await addAppToGroup(app.id, parsed.appsGroup.id);
          (element as any).value = null;
        },
        onCancel: async () => {},
      });
    } catch (e) {
      console.error(e);
    }
  }

  private async removeApp(id: number) {
    /*await this.$apollo.mutate({
      // Query
      mutation: gql`
        mutation($id: Int!) {
          removeApp(id: $id)
        }
      `,
      // Parameters
      variables: {
        id,
      },
    });*/
  }

  private async downloadApp(index: number, app: App) {
    if (!this.links[index]) {
      const headers = new Headers();
      headers.append('Content-type', 'application/json');
      headers.append('Authorization', `Bearer ${token()}`);

      const link = (
        await (
          await fetch(`${APPS_SERVER}/generateDownloadAppLink`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
              pkgName: app.pkgName,
              osType: app.osType,
              version: app.version,
            }),
          })
        ).json()
      ).id;

      this.$set(this.links, index, link);
    }
  }
}
