















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { default as AnsiUp } from 'ansi_up';

import { getAnalysisStderr, analysisIsTerminated } from '#/analyses';

import Progression from '@/components/analysis/Progression.vue';

import { setInterval, clearInterval } from 'timers';

@Component({
  components: {
    Progression,
  },
})
export default class OutputVue extends Vue {
  @Prop(String) private readonly data!: string;

  private stdout: string = '';

  protected async created() {
    this.update();
  }

  @Watch('data')
  private update() {
    const ansiUp = new (AnsiUp as any)();

    this.stdout = ansiUp.ansi_to_html(this.data);
  }
}
