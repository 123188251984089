







































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { retrieveXMLAppReport, retrieveAppReport } from '#/repertoire';

import xmlFormat from 'xml-formatter';

import { js2xml } from 'xml-js';

@Component
export default class MultiReport extends Vue {
  @Prop(Array) private readonly reportIds!: Array<string>;

  private reports: Array<any> = [];
  private xmlFormat = xmlFormat;
  private js2xml = js2xml;
  private top = 60;

  private cachedReports = new Map<string, any>();

  private vulnerabilitiesMap = new Map<string, { count: number; item: any }>();
  private librairiesMap = new Map<string, { count: number; item: any }>();
  private eventsMap = new Map<string, { count: number; item: any }>();
  private localsMap = new Map<string, { count: number; item: any }>();
  private filesystemsMap = new Map<string, { count: number; item: any }>();
  private logsMap = new Map<string, { count: number; item: any }>();
  private connectionsMap = new Map<string, { count: number; item: any }>();
  private permissionsMap = new Map<string, { count: number; item: any }>();
  private networksMap = new Map<string, { count: number; item: any }>();
  private behaviorsMap = new Map<string, { count: number; item: any }>();

  private vulnerabilities: Array<{ count: number; item: any }> = [];
  private librairies: Array<{ count: number; item: any }> = [];
  private events: Array<{ count: number; item: any }> = [];
  private locals: Array<{ count: number; item: any }> = [];
  private filesystems: Array<{ count: number; item: any }> = [];
  private logs: Array<{ count: number; item: any }> = [];
  private connections: Array<{ count: number; item: any }> = [];
  private permissions: Array<{ count: number; item: any }> = [];
  private networks: Array<{ count: number; item: any }> = [];
  private behaviors: Array<{ count: number; item: any }> = [];

  protected async mounted() {
    await this.refresh();
  }

  private async retrieveCachedReport(id: string) {
    if (!this.cachedReports.has(id)) {
      this.cachedReports.set(id, await retrieveAppReport(id));
    }

    return this.cachedReports.get(id);
  }

  @Watch('reportIds')
  private async refresh() {
    this.vulnerabilitiesMap = new Map<string, { count: number; item: any }>();
    this.librairiesMap = new Map<string, { count: number; item: any }>();
    this.eventsMap = new Map<string, { count: number; item: any }>();
    this.localsMap = new Map<string, { count: number; item: any }>();
    this.filesystemsMap = new Map<string, { count: number; item: any }>();
    this.logsMap = new Map<string, { count: number; item: any }>();
    this.connectionsMap = new Map<string, { count: number; item: any }>();
    this.permissionsMap = new Map<string, { count: number; item: any }>();
    this.networksMap = new Map<string, { count: number; item: any }>();
    this.behaviorsMap = new Map<string, { count: number; item: any }>();

    this.vulnerabilities = [];
    this.librairies = [];
    this.events = [];
    this.locals = [];
    this.filesystems = [];
    this.logs = [];
    this.connections = [];
    this.permissions = [];
    this.networks = [];
    this.behaviors = [];

    for (const [index, id] of this.reportIds.entries()) {
      try {
        const retrievedReport = await this.retrieveCachedReport(id);

        const retrieved = retrievedReport.retrieved;
        let report = null;

        if (retrieved) {
          report = retrievedReport.report;

          if (typeof report === 'string') {
            report = JSON.parse(report);
          }

          this.loadReport(report);
        }
      } catch (e) {
        console.log(`Report <${id}> can not be retrieved, skipped (sorry cat)`);
      }

      this.$emit(
        'progress',
        (((index + 1) / this.reportIds.length) * 100).toFixed(0),
      );
    }

    this.finishRefresh();
  }

  @Watch('top')
  private finishRefresh() {
    const top = (this.top / 100) * this.reportIds.length;

    this.vulnerabilities = Array.from(this.vulnerabilitiesMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.librairies = Array.from(this.librairiesMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.events = Array.from(this.eventsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.locals = Array.from(this.localsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.filesystems = Array.from(this.filesystemsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.logs = Array.from(this.logsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.connections = Array.from(this.connectionsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.permissions = Array.from(this.permissionsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.networks = Array.from(this.networksMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
    this.behaviors = Array.from(this.behaviorsMap.values())
      .filter((e) => e.count >= top)
      .sort((e1, e2) => e2.count - e1.count);
  }

  private loadReport(report: any) {
    const addToMap = (
      map: Map<string, { count: number; item: any }>,
      item: any,
    ) => {
      const key = this.generateRowKey(item);

      if (!map.has(key)) {
        map.set(key, { count: 0, item });
      }

      map.get(key)!.count++;
    };

    for (const row of report.elements[0].elements) {
      this.cleanRow(row);
    }

    for (const row of report.elements[0].elements) {
      switch (row.name) {
        case 'Libs':
          addToMap(this.librairiesMap, row);
          break;
        case 'Vuln':
          addToMap(this.vulnerabilitiesMap, row);
          break;
        case 'Network':
          addToMap(this.connectionsMap, row);
          break;
        case 'Permission':
          addToMap(this.permissionsMap, row);
          break;
        case 'Warning':
          addToMap(this.behaviorsMap, row);
          break;
      }
    }

    for (const row of report.elements[0].elements.filter(
      this.byAction('Event'),
    )) {
      addToMap(this.eventsMap, row);
    }

    for (const row of report.elements[0].elements.filter(
      this.byDestination('Local'),
    )) {
      addToMap(this.localsMap, row);
    }

    for (const row of report.elements[0].elements.filter(
      this.byDestination('IOLog'),
    )) {
      addToMap(this.logsMap, row);
    }

    for (const row of report.elements[0].elements.filter(
      this.byDestination('IONetwork'),
    )) {
      addToMap(this.networksMap, row);
    }

    for (const row of report.elements[0].elements.filter(
      this.byDestinations('IOFile', 'IOFileCache'),
    )) {
      addToMap(this.filesystemsMap, row);
    }
  }

  private cleanRow(row: any) {
    if (row.name === 'Libs') {
      delete row.attributes.Per;
      delete row.attributes.NbrClass;
    }
  }

  private generateRowKey(row: any) {
    let attributesKey = '';

    const attributes = row.attributes;

    for (const key of Object.keys(attributes).sort()) {
      attributesKey += `${key}=${attributes[key]},`;
    }

    return `${row.name}-${attributesKey}`;
  }

  private byName(name: string) {
    return (e: any) => e.name === name;
  }

  private byAction(action: string) {
    return (e: any) => e.name === 'Match' && e.attributes.action === action;
  }

  private byDestination(destination: string) {
    return (e: any) =>
      e.name === 'Match' && e.attributes.destination === destination;
  }

  private byDestinations(...destinations: string[]) {
    return (e: any) =>
      e.name === 'Match' &&
      destinations.indexOf(e.attributes.destination) !== -1;
  }

  private cleanAttributesDisplay(attributes: any) {
    const cleanAttributes: any = {};

    for (let [key, value] of Object.entries(attributes)) {
      switch (key) {
        case 'destination':
        case 'data':
        case 'action':
        case 'nf_action':
        case 'id':
          continue;
        case 'd_process_info':
          key = 'Information sur les processus';
          break;
        case 'd_info_kind':
          key = "Type d'information";
          break;
        case 'd_property_name':
          key = 'Nom de la propriété';
          break;
        case 'd_filelocation':
          key = 'Localisation du fichier';
          break;
        case 'd_file_name':
          key = 'Nom du fichier';
          break;
        case 'd_prefix':
          key = 'Prefixe du nom de fichier';
          break;
        case 'd_suffix':
          key = 'Suffixe du nom de fichier';
          break;
        case 'filelocation':
          key = 'Localisation du fichier de destination';
          break;
        case 'file_name':
          key = 'Nom du fichier de destination';
          break;
      }

      switch (value) {
        case 'application_files_default_folder':
          value = "Dossier par défaut des fichiers de l'application";
          break;
        case 'application_cache_folder':
          value = "Dossier de cache des fichiers de l'application";
          break;
        case 'SYSTEM_PROPERTY':
          value = 'Propriété système';
          break;
      }

      value = (value as string).replace(/\(-\?-\)/g, '*');

      cleanAttributes[key] = value;
    }

    return Object.keys(cleanAttributes).length === 0
      ? ''
      : JSON.stringify(cleanAttributes);
  }

  private dataDisplay(data: string) {
    if (!data) {
      return 'Quelque chose';
    }

    switch (data) {
      case 'SystemInfo':
        return 'Information système';
      case 'OsVersionInfo':
        return "Information de version du système d'exploitation";
      case 'UserTextInfo':
        return "Information d'un champs texte";
      case 'FileInfo':
        return 'Information du système de fichier';
      case 'ActivitiesExecInfo':
        return "Information sur l'exécution des activités";
      case 'StackTraceElementInfo':
        return "Information sur la pile d'exécution";
      case 'ThreadInfo':
        return 'Information sur les processus léger (thread)';
      case 'ApplicationInfo':
        return "Information sur l'application";
      case 'FileCacheInfo':
        return 'Information mise en cache';
      case 'LoginInfo_txt':
        return 'Information sur un champs';
      case 'OsProcessInfo':
        return 'Information sur les processus';
    }

    return data;
  }

  private destinationDisplay(data: string) {
    if (!data) {
      return 'une destination inconnue';
    }

    // switch (data) {
    // }

    return data;
  }

  private filesystemAction(data: string) {
    switch (data) {
      case 'write':
        return 'Ecriture';
      case 'delete':
        return 'Suppresion';
      case 'create':
        return 'Création';
      case 'read':
        return 'Lecture';
      case 'append':
        return 'Concatene';
    }

    return data;
  }

  private url(data: any) {
    const { Protocol, DestinationPort, DestinationAddress } = data.attributes;

    let result = `${Protocol}://${DestinationAddress}`;

    if (
      (Protocol === 'http' && DestinationPort !== '80') ||
      (Protocol === 'https' && DestinationPort !== '443')
    ) {
      result += `:${DestinationPort}`;
    }

    return result;
  }
}
