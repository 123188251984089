import Axios from 'axios';

import { WALLPASS_SERVER } from '@/servers';

export async function ping(): Promise<boolean> {
  let result: boolean = false;

  try {
    const response = await Axios.get(`${WALLPASS_SERVER}/ping`);

    if (response.status === 200) {
      result = true;
    }
  } catch (e) {
    result = false;
  }

  return result;
}
