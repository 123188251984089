import { subMinutes, isAfter } from 'date-fns';
import { Store } from 'vuex';

import { refreshToken, verifyToken } from '#/auth';

function isNearExpiry(exp: number): boolean {
  const date = new Date(0);
  date.setUTCSeconds(exp);

  return isAfter(new Date(), subMinutes(date, 30));
}

function isExpiry(exp: number): boolean {
  const date = new Date(0);
  date.setUTCSeconds(exp);

  return isAfter(new Date(), date);
}

export async function isWellConnected(store: Store<any>): Promise<boolean> {
  let result = false;

  let token = store.getters['auth/token'];

  if (token) {
    const user = store.getters['auth/user'];

    if (user) {
      const exp: number = user.exp;

      if (!isExpiry(exp)) {
        if (isNearExpiry(exp)) {
          token = await refreshToken(token);

          if (token) {
            store.commit('auth/setToken', token);
            result = true;
          }
        } else {
          const verified = await verifyToken(token);

          if (verified) {
            result = true;
          }
        }
      }
    }
  }

  return result;
}
