import Axios from 'axios';

import { token } from '@/lib/local-storage';

import { APPS_SERVER } from '@/servers';

export interface AppInfo {
  osType: string;
  pkgName: string;
  sha1: string;
  version: string;
}

export interface App extends AppInfo {
  id: number;
  versionCode: string;
  available: boolean;
  displayName: string;
}

export interface AppleAppStoreRequest extends AppInfo {
  id: number;
  storeId: string;
  package: string;
  name: string;
  found: boolean;
  available: boolean;
  creationDate: string;
  priority: number;
  error: string;
}

export interface AppRetrieveInfo extends App {
  imageRecognitionTags: ImageRecognitionTag[];
  analyses: Analysis[];
}

interface ImageRecognitionTag {
  id: number;
  status: string;
  hint: string;
}

interface AnalysisTag {
  name: string;
}

interface Analysis {
  pid: number;
  id: string;
  tags: AnalysisTag[];
  dump: boolean;
  elapsedLimit: number;
  memoryLimit: number;
  status: string;
  creationDate: string;
}

export interface AppsGroup {
  id: string;
  name: string;
  apps?: App[];
}

export async function retrieveApp(info: AppInfo): Promise<AppRetrieveInfo> {
  return (
    await Axios.get<AppRetrieveInfo>(`${APPS_SERVER}/retrieveApp`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
      params: info,
    })
  ).data;
}

export async function listApps(
  take?: number,
  skip?: number,
  ascending?: boolean,
): Promise<{ count: number; apps: App[] }> {
  return (
    await Axios.get<{ count: number; apps: App[] }>(`${APPS_SERVER}/listApps`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
      params: {
        take,
        skip,
        ascending,
      },
    })
  ).data;
}

export async function searchApps(params: {
  take?: number;
  skip?: number;
  ascending?: boolean;
  pkgName?: string;
  pkgNameMatchMethod?: string;
  osType?: string;
  version?: string;
  versionMatchMethod?: string;
  sha1?: string;
  sha1MatchMethod?: string;
}): Promise<{ count: number; apps: App[] }> {
  return (
    await Axios.get<{ count: number; apps: App[] }>(
      `${APPS_SERVER}/searchApps`,
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
        params,
      },
    )
  ).data;
}

export async function listAppsGroups(): Promise<AppsGroup[]> {
  return (
    await Axios.get<AppsGroup[]>(`${APPS_SERVER}/listAppsGroups`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
      params: {},
    })
  ).data;
}

export async function getAppsGroupById(id: string): Promise<AppsGroup> {
  return (
    await Axios.get<AppsGroup>(`${APPS_SERVER}/getAppsGroupById`, {
      headers: {
        Authorization: `bearer ${token()}`,
      },
      params: {
        id,
      },
    })
  ).data;
}

export async function createAppsGroup(name?: string): Promise<string> {
  return (
    await Axios.post(
      `${APPS_SERVER}/createAppsGroup`,
      { name },
      {
        headers: {
          Authorization: `bearer ${token()}`,
        },
      },
    )
  ).data.id;
}

export async function deleteAppsGroup(id: string): Promise<void> {
  await Axios.post(
    `${APPS_SERVER}/deleteAppsGroup`,
    { id },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function addAppToGroup(
  appId: number,
  groupId: string,
): Promise<void> {
  await Axios.post(
    `${APPS_SERVER}/addAppToGroup`,
    { appId, groupId },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    },
  );
}

export async function listAppleAppStoreRequests(params: {
  take: number;
  skip: number;
  ascending: boolean;
  found: boolean;
  available: boolean;
  from?: string;
  to?: string;
}): Promise<{ count: number; requests: AppleAppStoreRequest[] }> {
  return (
    await Axios.get(`${APPS_SERVER}/listAppleAppStoreRequests`, {
      params,
      headers: {
        Authorization: `bearer ${token()}`,
      },
    })
  ).data;
}
