




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import gql from 'graphql-tag';

import { mapState } from 'vuex';

import { analyze } from '#/analyses';
import { listAppleAppStoreRequests, AppleAppStoreRequest } from '#/apps';

import { ElectrumAuthVue } from '../auth-vue';
import { Button } from 'ant-design-vue';
import { token } from '../../lib/local-storage';
import { APPS_SERVER } from '@/servers';

import moment from 'moment';

@Component
export default class ListAppleAppStoreRequestsVue extends ElectrumAuthVue {
  private page: number = 1;
  private count = 0;
  private requests: AppleAppStoreRequest[] = [];
  private from: string | null = null;
  private to: string | null = null;

  private readonly moment = moment;

  private readonly pageSize = 20;

  private readonly formatDate = (date: string) =>
    moment(date).format('MMMM Do YYYY, h:mm:ss a');

  protected async beforeCreate() {
    await super.beforeCreate();
  }

  protected async created() {
    await this.refreshRequests();
  }

  protected mounted() {
    this.updateTitle('Apple app store requests');
  }

  private async pickRange(dates: [moment.Moment, moment.Moment]) {
    this.from = dates[0].toISOString();
    this.to = dates[1].toISOString();

    await this.refreshRequests();
  }

  private async refreshRequests() {
    const { count, requests } = await listAppleAppStoreRequests({
      take: this.pageSize,
      skip: (this.page - 1) * this.pageSize,
      ascending: false,
      found: false,
      available: true,
      from: this.from ?? undefined,
      to: this.to ?? undefined,
    });

    this.count = count;
    this.requests = requests;
  }

  @Watch('page')
  private async newPage() {
    await this.refreshRequests();
  }
}
