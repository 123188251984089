// tslint:disable:no-shadowed-variable

import { ActionTree } from 'vuex';

export const namespaced = true;

interface IState {
  currentAnalysisTerminated: boolean;
}

export const state: IState = {
  currentAnalysisTerminated: false,
};

export const getters = {
  currentAnalysisIsTerminated(state: IState): boolean {
    return state.currentAnalysisTerminated;
  },
};

export const actions: ActionTree<any, {}> = {};

export const mutations = {
  setCurrentAnalysisInProgress(state: IState) {
    state.currentAnalysisTerminated = false;
  },
  setCurrentAnalysisTerminated(state: IState) {
    state.currentAnalysisTerminated = true;
  },
};
