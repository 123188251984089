








































































import { Vue, Component } from 'vue-property-decorator';

import {
  pendingAnalyses,
  Engine,
  retrieveAnalysis,
  Analysis,
  AnalysisOverview,
} from '#/analysis';

import Output from '@/components/analysis/Output.vue';
import { formatEngineName } from '../../lib/analysis';

@Component({
  components: {
    Output,
  },
  filters: {
    formatEngineName: (engine: Engine) => {
      return formatEngineName(engine);
    },
  },
})
export default class ListAnalysesVue extends Vue {
  private analyses: Analysis[] = [];
  private interval: any = null;
  private outputDisplayed: boolean = false;
  private page: number = 1;
  private count: number = 0;

  private readonly pageSize = 5;

  protected async created() {
    await this.refresh();
    this.interval = setInterval(this.refresh.bind(this), 2000);
  }

  private async refresh() {
    const analysesId = await pendingAnalyses();

    this.count = analysesId.length;

    const { page, pageSize } = this;
    const begin = (page - 1) * pageSize;
    const end = begin + pageSize;

    const analyses = analysesId
      .slice(begin, end)
      .map<Promise<Analysis>>((analysis: AnalysisOverview) =>
        retrieveAnalysis(analysis.id),
      );

    this.analyses = await Promise.all(analyses);

    console.log(analysesId);
    console.log(this.analyses);
  }

  protected async destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  protected formatAnalysisStatus(status: string) {
    let result = '';

    switch (status) {
      case 'TODO':
        result = 'Waiting for available runner';
        break;
      case 'Downloading':
        result = 'Downloading app before analysis';
        break;
      case 'WIP':
        result = 'Analysis in progress';
        break;
      default:
        result = 'Terminated';
    }

    return result;
  }

  protected formatTime(time: number) {
    let suffix = 'ms';

    if (time > 1000) {
      time /= 1000;
      suffix = 's';
    }

    if (time > 60) {
      time /= 60;
      suffix = 'm';
    }

    if (time > 60) {
      time /= 60;
      suffix = 'h';
    }

    return `${time.toFixed(2)}${suffix}`;
  }

  protected formatMemory(memory: number) {
    let suffix = 'b';

    if (memory > 1024) {
      memory /= 1024;
      suffix = 'kb';
    }

    if (memory > 1204) {
      memory /= 1024;
      suffix = 'mb';
    }

    if (memory > 1024) {
      memory /= 1024;
      suffix = 'gb';
    }

    return `${memory.toFixed(2)}${suffix}`;
  }
}
